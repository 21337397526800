import { defineStore } from "pinia";
import { getDownloadUrl } from "./feathers/services/uploads/uploads.helpers";
import type { UploadFrontend } from "@artesa/shared";

export const usePdfStore = defineStore("pdf", () => {
  const currentPdf = ref<UploadFrontend | null>(null);
  const url = ref<string | null>(null);

  function showPDF(file: UploadFrontend) {
    currentPdf.value = file;
    url.value = getDownloadUrl(file);
  }

  function closePDF() {
    currentPdf.value = null;
    url.value = null;
  }

  const isOpen = computed(() => !!currentPdf.value || !!url.value);

  return {
    currentPdf: readonly(currentPdf),
    url: readonly(url),
    showPDF,
    closePDF,
    isOpen,
  };
});
