<template>
  <slot />
  <ArtesaLightbox v-if="generalStore.lightboxIndex !== null"></ArtesaLightbox>
  <PdfViewer v-if="showPdf"></PdfViewer>
  <ArtesaChatProvider v-if="chatStore.chats.length"></ArtesaChatProvider>
</template>

<script setup lang="ts">
const generalStore = useGeneralStore();

const pdfStore = usePdfStore();
const showPdf = computed(() => !!pdfStore.currentPdf && !!pdfStore.url);

const chatStore = useChatStore();

const router = useRouter();

const pushNotificationStore = usePushNotificationStore();

pushNotificationStore.showPermissionBox();

const stopWatchCurrentRoute = watch(router.currentRoute, (val, oldVal) => {
  if (oldVal?.name) {
    stopWatchCurrentRoute();
    generalStore.canGoBack = true;
  }
});

useImpersonationStore();
</script>

<script lang="ts">
import { usePushNotificationStore } from "./store/web-push.store";
import { useGeneralStore } from "./store/general.store";
import { useImpersonationStore } from "./store/impersonation.store";
import { usePdfStore } from "./store/pdf.store";
import { useChatStore } from "./store/chat.store";

const ArtesaLightbox = defineAsyncComponent(() => import("./components/ArtesaLightbox.vue"));
const PdfViewer = defineAsyncComponent(() => import("./components/PdfViewer.vue"));
const ArtesaChatProvider = defineAsyncComponent(
  () => import("./components/chat/ArtesaChatProvider.vue"),
);
</script>
