import { host } from "../../feathers.client";
import type { UploadFrontend } from "@artesa/shared";
import { useGeneralStore } from "@/store/general.store";
import { usePdfStore } from "@/store/pdf.store";
import { downloadFromUrl } from "@/helpers/fileHelpers";
import { getStoreByName } from "../../base-store";

export function uploadIsImage(upload: UploadFrontend): boolean {
  return upload.mimeType?.startsWith("image/") ?? false;
}

export function uploadIsPdf(upload: UploadFrontend): boolean {
  return upload.mimeType === "application/pdf";
}

export function uploadIsVideo(upload: UploadFrontend): boolean {
  return upload.mimeType?.startsWith("video/") ?? false;
}

export function uploadIsAudio(upload: UploadFrontend): boolean {
  return upload.mimeType?.startsWith("audio/") ?? false;
}

export function uploadIsExcel(upload: UploadFrontend): boolean {
  return (
    upload.mimeType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
    upload.mimeType === "application/vnd.ms-excel" ||
    upload.mimeType === "text/csv" ||
    upload.originalName.endsWith(".xls") ||
    upload.originalName.endsWith(".xlsx") ||
    upload.originalName.endsWith(".csv") ||
    upload.originalName.endsWith(".XLS") ||
    upload.originalName.endsWith(".XLSX") ||
    upload.originalName.endsWith(".CSV")
  );
}

export function uploadIsWord(upload: UploadFrontend): boolean {
  return (
    upload.mimeType === "application/msword" ||
    upload.mimeType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
    upload.originalName.endsWith(".doc") ||
    upload.originalName.endsWith(".docx") ||
    upload.originalName.endsWith(".DOC") ||
    upload.originalName.endsWith(".DOCX")
  );
}

export function uploadIsEmail(upload: UploadFrontend): boolean {
  return (
    upload.mimeType === "message/rfc822" ||
    upload.originalName.endsWith(".eml") ||
    upload.originalName.endsWith(".EML") ||
    upload.originalName.endsWith(".msg") ||
    upload.originalName.endsWith(".MSG")
  );
}

export function uploadIsXml(upload: UploadFrontend): boolean {
  return (
    upload.mimeType === "application/xml" ||
    upload.originalName.endsWith(".xml") ||
    upload.originalName.endsWith(".XML")
  );
}

export function uploadIsTechnicalDrawing(upload: UploadFrontend): boolean {
  return (
    upload.originalName.endsWith(".dwg") ||
    upload.originalName.endsWith(".DWG") ||
    upload.originalName.endsWith(".dxf") ||
    upload.originalName.endsWith(".DXF")
  );
}

export function uploadIsZip(upload: UploadFrontend): boolean {
  return (
    upload.mimeType === "application/zip" ||
    upload.originalName.endsWith(".zip") ||
    upload.originalName.endsWith(".ZIP") ||
    upload.originalName.endsWith(".rar") ||
    upload.originalName.endsWith(".RAR") ||
    upload.originalName.endsWith(".7z") ||
    upload.originalName.endsWith(".7Z") ||
    upload.originalName.endsWith(".tar") ||
    upload.originalName.endsWith(".TAR") ||
    upload.originalName.endsWith(".gz") ||
    upload.originalName.endsWith(".GZ")
  );
}

export function getDownloadUrl(upload: UploadFrontend): string | null {
  if (!upload) {
    return null;
  }

  if (upload.downloadToken) {
    return `${host}/files/${upload.id}/${encodeURIComponent(
      upload.originalName,
    )}?token=${upload.downloadToken}`;
  } else if (upload.blobUrl) {
    return upload.blobUrl;
  } else {
    return null;
  }
}

export async function openUpload(upload: UploadFrontend, additionalUploads: UploadFrontend[] = []) {
  if (uploadIsImage(upload)) {
    const url = getDownloadUrl(upload);
    if (!url) {
      return;
    }
    const generalStore = useGeneralStore();

    if (!additionalUploads?.includes(upload)) {
      additionalUploads = [upload, ...additionalUploads];
    }

    additionalUploads = additionalUploads.filter(uploadIsImage);

    const indexOfUpload = additionalUploads.indexOf(upload);

    generalStore.lightboxItems = additionalUploads.map(upload => ({
      src: getDownloadUrl(upload) ?? "",
      title: upload.originalName,
    }));

    generalStore.lightboxIndex = indexOfUpload ?? 0;
  } else if (uploadIsPdf(upload)) {
    const pdfStore = usePdfStore();
    pdfStore.showPDF(upload);
  } else {
    openBlank(upload);
  }
}

export function openBlank(upload: UploadFrontend) {
  const downloadUrl = getDownloadUrl(upload);
  if (!downloadUrl) {
    return;
  }
  downloadFromUrl(downloadUrl, { fileName: upload.originalName });
}

// TODO: Add a generic for the input to type the output properly based on associatedServicePath -> ModelRegisterFrontend
export function useAssociatedItemByUploadUuid(uploads: Ref<UploadFrontend[]>) {
  return computed(() => {
    const result: Record<string, any> = {};
    uploads.value.forEach(upload => {
      const servicePath = upload.associatedServicePath;
      const id = upload.associatedId;
      if (!servicePath || !id) {
        return;
      }

      const useItemStore = getStoreByName(servicePath as any);
      if (!useItemStore) {
        return;
      }

      const itemStore = useItemStore().createScope();

      const { data } = itemStore.useGet({
        id: upload.associatedId,
      });

      result[upload.uuid] = data.value;
    });

    return result;
  });
}

type AskForFileUploadOptions = {
  /**
   * native input multiple attribute
   *
   * see: https://www.w3schools.com/tags/att_input_multiple.asp
   *
   * @default false
   */
  multiple?: boolean;
  /**
   * native input accept attribute. A string with comma separated mime types or an array of mime types
   *
   * see: https://www.w3schools.com/tags/att_input_accept.asp
   *
   * @default false
   */
  accept?: string | string[];
};

export function askForFileUpload(options?: AskForFileUploadOptions): Promise<File[] | undefined> {
  return new Promise(resolve => {
    const input = document.createElement("input");
    input.type = "file";

    if (options?.multiple) {
      input.multiple = true;
    }
    if (options?.accept) {
      input.accept = Array.isArray(options.accept) ? options.accept.join(",") : options.accept;
    }

    input.onchange = () => {
      if (!input.files) {
        resolve(undefined);
        return;
      }
      const files = Array.from(input.files);
      if (!files.length) {
        resolve(undefined);
        return;
      }
      resolve(files);
    };
    
    input.click();
  });
}
