import { feathers } from "@feathersjs/feathers";
import socketio from "@feathersjs/socketio-client";

import io from "socket.io-client";
import auth, { MemoryStorage } from "@feathersjs/authentication-client";
import {
  type AssignmentEventFrontend,
  type UserAbsenceFrontend,
  type TimeSheetDayFrontend,
  type TimeSheetMonthFrontend,
  type UserFrontend,
  type CustomerFrontend,
  type CustomerImportType,
  type RoleFrontend,
  type RoleTypePublic,
  calculateWorkingTimesServiceMethods,
  userAbsenceNormsServiceMethods,
  USER_ABSENCE_METHODS,
  ASSIGNMENT_EVENT_WORK_PERIOD_METHODS,
  NOTIFICATION_METHODS,
  type CalculateWorkingTimesServiceType,
  type IUserAbsenceNormsService,
  type IUserAbsencesService,
  type IAssignmentEventWorkPeriodService,
} from "@artesa/shared";
import {
  type CustomerService,
  type InvoiceService,
  type SubscriptionService,
  type SubscriptionItemService,
  type PayoutService,
  type BalanceTransactionService,
} from "feathers-stripe";
import hooks from "./feathers.hooks";
import { host, IdbStorage } from "./feathers.common";
import type { FeathersService, Params, Service } from "@feathersjs/feathers";
import type SocketIoType from "socket.io-client";

const socket: ReturnType<typeof SocketIoType> = io(host, {
  transports: ["websocket"],
  rejectUnauthorized: false,
  reconnectionDelay: 5000,
});

/**
 * Raw service calls return string instead of Date
 */
export type DateToString<O> = {
  [Key in keyof O]: Date extends O[Key] ? Exclude<O[Key], Date> | string : DateToString<O[Key]>;
};

export interface Services {
  "calculate-working-times": CalculateWorkingTimesServiceType;
  "user-absence-norms": IUserAbsenceNormsService;
  "stripe/customers": CustomerService;
  "stripe/invoices": InvoiceService;
  "stripe/subscriptions": SubscriptionService;
  "stripe/subscription-items": SubscriptionItemService;
  "stripe/balance-transactions": BalanceTransactionService;
  "stripe/payouts": PayoutService;
  "maps/find-place-from-text": any;
  "assignment-event-work-periods": IAssignmentEventWorkPeriodService;
  "assignment-events": Service<DateToString<AssignmentEventFrontend>>;
  "user-absences": Service<DateToString<UserAbsenceFrontend>> &
    Exclude<IUserAbsencesService, Service<any>>;
  "time-sheet-days": Service<DateToString<TimeSheetDayFrontend>>;
  "time-sheet-months": Service<DateToString<TimeSheetMonthFrontend>>;
  customers: Service<CustomerFrontend> & {
    import(
      data: CustomerImportType,
      params?: Params,
    ): Promise<{
      importReference: string;
      created: number;
      patched: number;
    }>;
  };
  users: Service<DateToString<UserFrontend>>;
  notifications: { getApplicationServerKey(): Promise<{ applicationServerKey }> } & FeathersService;
  roles: Service<RoleFrontend> & {
    currentPeriodRoles: (companyId: number) => Promise<Record<RoleTypePublic, number>>;
  };
  // TODO: leave this for now
  [key: string]: any;
}

export const app = feathers<Services>();

app.hooks(hooks);

export const connection = socketio<Services>(socket);

app.configure(connection);

const useMemoryStorage = "location" in globalThis && new URL(globalThis.location.href).searchParams.has("temp_access");

app.configure(auth({
  storage: useMemoryStorage ? new MemoryStorage() : new IdbStorage()
}));

app.use("calculate-working-times", connection.service("calculate-working-times"), {
  methods: calculateWorkingTimesServiceMethods,
});

app.use("user-absence-norms", connection.service("user-absence-norms"), {
  methods: userAbsenceNormsServiceMethods,
});

app.use("user-absences", connection.service("user-absences"), {
  methods: USER_ABSENCE_METHODS,
});

app.use("stripe/customers", connection.service("stripe/customers"), {
  methods: ["find", "search", "get"],
});

app.use("stripe/invoices", connection.service("stripe/invoices"), {
  methods: ["find", "search", "get"],
});

app.use("stripe/subscriptions", connection.service("stripe/subscriptions"), {
  methods: ["find", "get", "search"],
});

app.use("stripe/subscription-items", connection.service("stripe/subscription-items"), {
  methods: ["find", "get", "search"],
});

app.use("assignment-event-work-periods", connection.service("assignment-event-work-periods"), {
  methods: ASSIGNMENT_EVENT_WORK_PERIOD_METHODS,
});

app.use("notifications", connection.service("notifications"), {
  methods: NOTIFICATION_METHODS,
});

app.use("customers", connection.service("customers"), {
  methods: ["find", "get", "create", "update", "patch", "remove", "import"],
});

app.use("roles", connection.service("roles"), {
  methods: ["find", "get", "create", "update", "patch", "remove", "currentPeriodRoles"],
});

export default app;

export { socket, host };
