import { NotAuthenticated } from "@feathersjs/errors";
import { defineHooks, paramsForServer } from "feathers-utils";
import type { HookContext, NextFunction } from "@feathersjs/feathers";
import { Sentry } from "@/main.plugins/main.sentry";
import type { Span } from "@sentry/browser";

const debug = (whitelist?: string[]) => (context: HookContext) => {
  if (whitelist?.length && !whitelist.includes(context.path)) {
    return;
  }

  const meta: any[] = [context.path, context.method];
  if (context.method === "create") {
    meta.push(context.data);
  } else if (context.method === "find") {
    meta.push(context.params.query);
  } else {
    meta.push(context.id, context.data, context.params.query);
  }

  if (context.result) {
    meta.push(context.result);
  }

  if (context.error) {
    meta.push(context.error);
  }

  console.log(...meta);
};

const checkPerformance = () => (context: HookContext) => {
  if (context.type === "before") {
    context.params.startTime = Date.now();
  } else {
    if (!context.params.startTime) {
      return;
    }
    const endTime = Date.now();
    const lines = [
      `perf ${context.path}/${context.method} ${endTime - context.params.startTime}ms`,
    ];

    if (context.method === "create") {
      lines.push(Array.isArray(context.data) ? `${context.data.length} items` : "1 item");
    } else if (context.method === "find") {
      lines.push(JSON.stringify(context.params.query));
    } else if (context.method === "patch" || context.method === "remove") {
      lines.push("id: " + context.id);
      if (context.params?.query) {
        lines.push(JSON.stringify(context.params.query));
      }
    }
    console.log(lines.join(" "));
  }
};

let overarchingSpan: Span | null = null

export default defineHooks({
  around: {
    all: [
      async (context: HookContext, next: NextFunction) => {
        if (!context.params.span) {
          if (!overarchingSpan) {
            overarchingSpan = Sentry.getActiveSpan() ?? null;
          }
          if (overarchingSpan) {
            context.params.span = overarchingSpan;
          }
        }

        const span = Sentry.startInactiveSpan(
          {
            name: `${context.method}: /${context.path}`,
            op: "websocket",
            forceTransaction: true,
            attributes: {
              method: context.method,
              service: context.path,
            },
            parentSpan: context.params.span
          },
        );
        // Create `sentry-trace` header
        const sentryTraceHeader = Sentry.spanToTraceHeader(span);
        // Create `baggage` header
        const sentryBaggageHeader = Sentry.spanToBaggageHeader(span);

        context.params["$sentry-trace"] = sentryTraceHeader;
        context.params["$baggage"] = sentryBaggageHeader;
        context.params["$span"] = Sentry.spanToJSON(span);
        context.params.span = span;
        return await next()
          .catch(error => {
            if (
              context.path === "authentication" ||
              !(context.error instanceof NotAuthenticated)
            ) {
              span?.setStatus({ code: 2 /* SPAN_STATUS_ERROR */, message: "unauthenticated" });
              span?.end();
              return;
            }
            span?.setStatus({ code: 2 /* SPAN_STATUS_ERROR */, message: "unknown_error" });
            return Promise.reject(error);
          })
          .finally(() => {
            if (context.params.query)
              Sentry.getIsolationScope().setContext("query", context.params.query);
            if (context.params.$populateParams)
              Sentry.getIsolationScope().setContext(
                "populateParams",
                context.params.$populateParams,
              );
            span.end();
          });
      },
    ],
  },
  before: {
    all: [
      paramsForServer("$populateParams", "$sentry-trace", "$baggage", "$span"),
      // checkPerformance(),
    ],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },

  after: {
    all: [
      // checkPerformance(),
      // debug(),
    ],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },

  error: {
    all: [
      context => {
        if (context.path === "authentication" || !(context.error instanceof NotAuthenticated)) {
          return;
        }

        const useAuthStore = context.app.get("useAuthStore");

        if (!useAuthStore) {
          return;
        }

        const authStore = useAuthStore();
        authStore.logout();
      },
    ],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});
