(function(){"use strict";try{if(typeof document<"u"){var t=document.createElement("style");t.appendChild(document.createTextNode("._wrap_12rlz_1{overflow:hidden;position:relative}._scroll-container_12rlz_6{overflow:auto}._shadow-top_12rlz_10,._shadow-right_12rlz_11,._shadow-bottom_12rlz_12,._shadow-left_12rlz_13{position:absolute;border-radius:.5em;opacity:0;transition:opacity .4s;pointer-events:none}._shadow-top_12rlz_10,._shadow-bottom_12rlz_12{right:0;left:0;height:.5em;border-top-right-radius:0;border-top-left-radius:0;background-image:linear-gradient(#0000002b,#fff0)}._shadow-top_12rlz_10{top:0}._shadow-bottom_12rlz_12{bottom:0;transform:rotate(180deg)}._shadow-right_12rlz_11,._shadow-left_12rlz_13{top:0;bottom:0;width:.5em;border-top-left-radius:0;border-bottom-left-radius:0;background-image:linear-gradient(90deg,#0000002b,#fff0)}._shadow-right_12rlz_11{right:0;transform:rotate(180deg)}._shadow-left_12rlz_13{left:0}._is-active_12rlz_59{opacity:1}")),document.head.appendChild(t)}}catch(o){console.error("vite-plugin-css-injected-by-js",o)}})();
var kt = Object.defineProperty;
var We = (e) => {
  throw TypeError(e);
};
var Nt = (e, r, t) => r in e ? kt(e, r, { enumerable: !0, configurable: !0, writable: !0, value: t }) : e[r] = t;
var Re = (e, r, t) => Nt(e, typeof r != "symbol" ? r + "" : r, t), Ie = (e, r, t) => r.has(e) || We("Cannot " + t);
var y = (e, r, t) => (Ie(e, r, "read from private field"), t ? t.call(e) : r.get(e)), $ = (e, r, t) => r.has(e) ? We("Cannot add the same private member more than once") : r instanceof WeakSet ? r.add(e) : r.set(e, t), C = (e, r, t, n) => (Ie(e, r, "write to private field"), n ? n.call(e, t) : r.set(e, t), t), j = (e, r, t) => (Ie(e, r, "access private method"), t);
var pe = (e, r, t, n) => ({
  set _(i) {
    C(e, r, i, t);
  },
  get _() {
    return y(e, r, n);
  }
});
import { getCurrentScope as zt, onScopeDispose as Lt, unref as ce, ref as S, readonly as G, watch as _, onMounted as nt, nextTick as xe, isRef as Te, getCurrentInstance as it, shallowRef as A, watchEffect as st, computed as q, defineComponent as J, renderSlot as B, openBlock as fe, createElementBlock as ke, normalizeClass as D, normalizeStyle as F, createBlock as at, resolveDynamicComponent as ot, withCtx as ut, toRef as Se, toValue as N, isReactive as Wt, createCommentVNode as Rt, reactive as ge, createElementVNode as K, mergeProps as Dt, normalizeProps as Mt, guardReactiveProps as Ft } from "vue";
function R(e) {
  return zt() ? (Lt(e), !0) : !1;
}
function Bt(e, r, { enumerable: t = !1, unwrap: n = !0 } = {}) {
  for (const [i, s] of Object.entries(r))
    i !== "value" && (Te(s) && n ? Object.defineProperty(e, i, {
      get() {
        return s.value;
      },
      set(f) {
        s.value = f;
      },
      enumerable: t
    }) : Object.defineProperty(e, i, { value: s, enumerable: t }));
  return e;
}
function H(e) {
  return typeof e == "function" ? e() : ce(e);
}
const Ae = typeof window < "u" && typeof document < "u";
typeof WorkerGlobalScope < "u" && globalThis instanceof WorkerGlobalScope;
const lt = (e) => e != null, Vt = Object.prototype.toString, Gt = (e) => Vt.call(e) === "[object Object]", X = () => {
};
function ct(e, r) {
  function t(...n) {
    return new Promise((i, s) => {
      Promise.resolve(e(() => r.apply(this, n), { fn: r, thisArg: this, args: n })).then(i).catch(s);
    });
  }
  return t;
}
const ft = (e) => e();
function Ne(e, r = {}) {
  let t, n, i = X;
  const s = (u) => {
    clearTimeout(u), i(), i = X;
  };
  return (u) => {
    const a = H(e), l = H(r.maxWait);
    return t && s(t), a <= 0 || l !== void 0 && l <= 0 ? (n && (s(n), n = null), Promise.resolve(u())) : new Promise((o, d) => {
      i = r.rejectOnCancel ? d : o, l && !n && (n = setTimeout(() => {
        t && s(t), n = null, o(u());
      }, l)), t = setTimeout(() => {
        n && s(n), n = null, o(u());
      }, a);
    });
  };
}
function Ht(e = ft) {
  const r = S(!0);
  function t() {
    r.value = !1;
  }
  function n() {
    r.value = !0;
  }
  const i = (...s) => {
    r.value && e(...s);
  };
  return { isActive: G(r), pause: t, resume: n, eventFilter: i };
}
function Ut(e) {
  return it();
}
function Yt(e, r = 200, t = {}) {
  return ct(
    Ne(r, t),
    e
  );
}
function dt(e, r = 200, t = {}) {
  const n = S(e.value), i = Yt(() => {
    n.value = e.value;
  }, r, t);
  return _(e, () => i()), n;
}
function vt(e, r, t = {}) {
  const {
    eventFilter: n = ft,
    ...i
  } = t;
  return _(
    e,
    ct(
      n,
      r
    ),
    i
  );
}
function ht(e, r = !0, t) {
  Ut() ? nt(e, t) : r ? e() : xe(e);
}
function mt(e, r = 1e3, t = {}) {
  const {
    immediate: n = !0,
    immediateCallback: i = !1
  } = t;
  let s = null;
  const f = S(!1);
  function u() {
    s && (clearInterval(s), s = null);
  }
  function a() {
    f.value = !1, u();
  }
  function l() {
    const o = H(r);
    o <= 0 || (f.value = !0, i && e(), u(), f.value && (s = setInterval(e, o)));
  }
  if (n && Ae && l(), Te(r) || typeof r == "function") {
    const o = _(r, () => {
      f.value && Ae && l();
    });
    R(o);
  }
  return R(a), {
    isActive: f,
    pause: a,
    resume: l
  };
}
function pt(e, r, t = {}) {
  const {
    debounce: n = 0,
    maxWait: i = void 0,
    ...s
  } = t;
  return vt(
    e,
    r,
    {
      ...s,
      eventFilter: Ne(n, { maxWait: i })
    }
  );
}
function Qt(e, r, t) {
  let n;
  Te(t) ? n = {
    evaluating: t
  } : n = t || {};
  const {
    lazy: i = !1,
    evaluating: s = void 0,
    shallow: f = !0,
    onError: u = X
  } = n, a = S(!i), l = f ? A(r) : S(r);
  let o = 0;
  return st(async (d) => {
    if (!a.value)
      return;
    o++;
    const h = o;
    let m = !1;
    s && Promise.resolve().then(() => {
      s.value = !0;
    });
    try {
      const c = await e((p) => {
        d(() => {
          s && (s.value = !1), m || p();
        });
      });
      h === o && (l.value = c);
    } catch (c) {
      u(c);
    } finally {
      s && h === o && (s.value = !1), m = !0;
    }
  }), i ? q(() => (a.value = !0, l.value)) : l;
}
const Z = Ae ? window : void 0;
function V(e) {
  var r;
  const t = H(e);
  return (r = t == null ? void 0 : t.$el) != null ? r : t;
}
function qe(...e) {
  let r, t, n, i;
  if (typeof e[0] == "string" || Array.isArray(e[0]) ? ([t, n, i] = e, r = Z) : [r, t, n, i] = e, !r)
    return X;
  Array.isArray(t) || (t = [t]), Array.isArray(n) || (n = [n]);
  const s = [], f = () => {
    s.forEach((o) => o()), s.length = 0;
  }, u = (o, d, h, m) => (o.addEventListener(d, h, m), () => o.removeEventListener(d, h, m)), a = _(
    () => [V(r), H(i)],
    ([o, d]) => {
      if (f(), !o)
        return;
      const h = Gt(d) ? { ...d } : d;
      s.push(
        ...t.flatMap((m) => n.map((c) => u(o, m, c, h)))
      );
    },
    { immediate: !0, flush: "post" }
  ), l = () => {
    a(), f();
  };
  return R(l), l;
}
function Xt() {
  const e = S(!1), r = it();
  return r && nt(() => {
    e.value = !0;
  }, r), e;
}
function Oe(e) {
  const r = Xt();
  return q(() => (r.value, !!e()));
}
function Jt(e, r, t = {}) {
  const { window: n = Z, ...i } = t;
  let s;
  const f = Oe(() => n && "MutationObserver" in n), u = () => {
    s && (s.disconnect(), s = void 0);
  }, a = q(() => {
    const h = H(e), m = (Array.isArray(h) ? h : [h]).map(V).filter(lt);
    return new Set(m);
  }), l = _(
    () => a.value,
    (h) => {
      u(), f.value && h.size && (s = new MutationObserver(r), h.forEach((m) => s.observe(m, i)));
    },
    { immediate: !0, flush: "post" }
  ), o = () => s == null ? void 0 : s.takeRecords(), d = () => {
    l(), u();
  };
  return R(d), {
    isSupported: f,
    stop: d,
    takeRecords: o
  };
}
function Zt(e, r = {}) {
  const {
    immediate: t = !0,
    fpsLimit: n = void 0,
    window: i = Z
  } = r, s = S(!1), f = n ? 1e3 / n : null;
  let u = 0, a = null;
  function l(h) {
    if (!s.value || !i)
      return;
    u || (u = h);
    const m = h - u;
    if (f && m < f) {
      a = i.requestAnimationFrame(l);
      return;
    }
    u = h, e({ delta: m, timestamp: h }), a = i.requestAnimationFrame(l);
  }
  function o() {
    !s.value && i && (s.value = !0, u = 0, a = i.requestAnimationFrame(l));
  }
  function d() {
    s.value = !1, a != null && i && (i.cancelAnimationFrame(a), a = null);
  }
  return t && o(), R(d), {
    isActive: G(s),
    pause: d,
    resume: o
  };
}
function Kt(e, r = {}) {
  const { window: t = Z } = r, n = Oe(() => t && "matchMedia" in t && typeof t.matchMedia == "function");
  let i;
  const s = S(!1), f = (l) => {
    s.value = l.matches;
  }, u = () => {
    i && ("removeEventListener" in i ? i.removeEventListener("change", f) : i.removeListener(f));
  }, a = st(() => {
    n.value && (u(), i = t.matchMedia(H(e)), "addEventListener" in i ? i.addEventListener("change", f) : i.addListener(f), s.value = i.matches);
  });
  return R(() => {
    a(), u(), i = void 0;
  }), s;
}
function $e(e, r, t = {}) {
  const { window: n = Z, ...i } = t;
  let s;
  const f = Oe(() => n && "ResizeObserver" in n), u = () => {
    s && (s.disconnect(), s = void 0);
  }, a = q(() => {
    const d = H(e);
    return Array.isArray(d) ? d.map((h) => V(h)) : [V(d)];
  }), l = _(
    a,
    (d) => {
      if (u(), f.value && n) {
        s = new ResizeObserver(r);
        for (const h of d)
          h && s.observe(h, i);
      }
    },
    { immediate: !0, flush: "post" }
  ), o = () => {
    u(), l();
  };
  return R(o), {
    isSupported: f,
    stop: o
  };
}
function De(e, r = {}) {
  const {
    reset: t = !0,
    windowResize: n = !0,
    windowScroll: i = !0,
    immediate: s = !0,
    updateTiming: f = "sync"
  } = r, u = S(0), a = S(0), l = S(0), o = S(0), d = S(0), h = S(0), m = S(0), c = S(0);
  function p() {
    const g = V(e);
    if (!g) {
      t && (u.value = 0, a.value = 0, l.value = 0, o.value = 0, d.value = 0, h.value = 0, m.value = 0, c.value = 0);
      return;
    }
    const w = g.getBoundingClientRect();
    u.value = w.height, a.value = w.bottom, l.value = w.left, o.value = w.right, d.value = w.top, h.value = w.width, m.value = w.x, c.value = w.y;
  }
  function v() {
    f === "sync" ? p() : f === "next-frame" && requestAnimationFrame(() => p());
  }
  return $e(e, v), _(() => V(e), (g) => !g && v()), Jt(e, v, {
    attributeFilter: ["style", "class"]
  }), i && qe("scroll", v, { capture: !0, passive: !0 }), n && qe("resize", v, { passive: !0 }), ht(() => {
    s && v();
  }), {
    height: u,
    bottom: a,
    left: l,
    right: o,
    top: d,
    width: h,
    x: m,
    y: c,
    update: v
  };
}
function er(e, r, t = {}) {
  const {
    root: n,
    rootMargin: i = "0px",
    threshold: s = 0,
    window: f = Z,
    immediate: u = !0
  } = t, a = Oe(() => f && "IntersectionObserver" in f), l = q(() => {
    const c = H(e);
    return (Array.isArray(c) ? c : [c]).map(V).filter(lt);
  });
  let o = X;
  const d = S(u), h = a.value ? _(
    () => [l.value, V(n), d.value],
    ([c, p]) => {
      if (o(), !d.value || !c.length)
        return;
      const v = new IntersectionObserver(
        r,
        {
          root: V(p),
          rootMargin: i,
          threshold: s
        }
      );
      c.forEach((g) => g && v.observe(g)), o = () => {
        v.disconnect(), o = X;
      };
    },
    { immediate: u, flush: "post" }
  ) : X, m = () => {
    o(), h(), d.value = !1;
  };
  return R(m), {
    isSupported: a,
    isActive: d,
    pause() {
      o(), d.value = !1;
    },
    resume() {
      d.value = !0;
    },
    stop: m
  };
}
function tr(e = {}) {
  const {
    controls: r = !1,
    interval: t = "requestAnimationFrame"
  } = e, n = S(/* @__PURE__ */ new Date()), i = () => n.value = /* @__PURE__ */ new Date(), s = t === "requestAnimationFrame" ? Zt(i, { immediate: !0 }) : mt(i, t, { immediate: !0 });
  return r ? {
    now: n,
    ...s
  } : n;
}
function rr(e = {}) {
  const {
    window: r = Z,
    initialWidth: t = Number.POSITIVE_INFINITY,
    initialHeight: n = Number.POSITIVE_INFINITY,
    listenOrientation: i = !0,
    includeScrollbar: s = !0,
    type: f = "inner"
  } = e, u = S(t), a = S(n), l = () => {
    r && (f === "outer" ? (u.value = r.outerWidth, a.value = r.outerHeight) : s ? (u.value = r.innerWidth, a.value = r.innerHeight) : (u.value = r.document.documentElement.clientWidth, a.value = r.document.documentElement.clientHeight));
  };
  if (l(), ht(l), qe("resize", l, { passive: !0 }), i) {
    const o = Kt("(orientation: portrait)");
    _(o, () => l());
  }
  return { width: u, height: a };
}
const Kn = /* @__PURE__ */ J({
  __name: "AsyncComputed",
  props: {
    evaluationCallback: {},
    initialState: {},
    options: {}
  },
  setup(e) {
    const r = e, t = A(!1), n = Qt(r.evaluationCallback, r.initialState, {
      ...r.options,
      evaluating: t
    });
    return (i, s) => B(i.$slots, "default", {
      evaluating: t.value,
      value: ce(n)
    });
  }
}), nr = ["href", "target"], ei = /* @__PURE__ */ J({
  inheritAttrs: !1,
  __name: "ConditionalLink",
  props: {
    href: {},
    target: { default: void 0 },
    fallbackTag: { default: void 0 },
    urlClass: { default: void 0 },
    fallbackClass: { default: void 0 }
  },
  setup(e) {
    const r = e;
    return (t, n) => r.href ? (fe(), ke("a", {
      key: 0,
      href: r.href,
      target: r.target,
      class: D([t.$attrs.class, t.urlClass]),
      style: F(t.$attrs.style)
    }, [
      B(t.$slots, "default")
    ], 14, nr)) : r.fallbackTag ? (fe(), at(ot(r.fallbackTag), {
      key: 1,
      class: D([t.$attrs.class, t.fallbackClass]),
      style: F(t.$attrs.style)
    }, {
      default: ut(() => [
        B(t.$slots, "default")
      ]),
      _: 3
      /* FORWARDED */
    }, 8, ["class", "style"])) : B(t.$slots, "default", { key: 2 });
  }
}), ti = /* @__PURE__ */ J({
  __name: "DeclarativeWatch",
  props: {
    getter: { type: Function }
  },
  emits: ["watch"],
  setup(e, { emit: r }) {
    const t = e, n = r, i = q(() => t.getter);
    return _(
      () => i.value(),
      (s, f) => {
        n("watch", s, f);
      }
    ), (s, f) => B(s.$slots, "default");
  }
}), ir = (e, r = 200, t) => {
  const { maxWait: n, rejectOnCancel: i, ...s } = t || {}, f = q(e, s), u = dt(f, r, { maxWait: n, rejectOnCancel: i });
  return q(() => u.value);
};
function ri(e, r) {
  if ((r == null ? void 0 : r.onlyIf) === void 0)
    return q(e, r);
  const { onlyIf: t } = r || {};
  let n;
  const i = S();
  let s = !0, f, u = !1;
  return _(() => e(n), (a) => {
    if (n = a, s) {
      i.value = a, s = !1;
      return;
    }
    if (!N(t)) {
      f = a, u = !0;
      return;
    }
    i.value = a;
  }, { flush: "sync", immediate: !0 }), _(Se(t), (a) => {
    u && a && (i.value = f, f = void 0, u = !1);
  }, { flush: "sync" }), q(() => i.value, r);
}
function yt(e, r) {
  const { comparator: t = () => !0 } = r || {};
  return q((n) => {
    const i = e();
    return t(i, n) ? n : i;
  }, r);
}
var sr = Object.getOwnPropertyNames, ar = Object.getOwnPropertySymbols, or = Object.prototype.hasOwnProperty;
function Me(e, r) {
  return function(n, i, s) {
    return e(n, i, s) && r(n, i, s);
  };
}
function ye(e) {
  return function(t, n, i) {
    if (!t || !n || typeof t != "object" || typeof n != "object")
      return e(t, n, i);
    var s = i.cache, f = s.get(t), u = s.get(n);
    if (f && u)
      return f === n && u === t;
    s.set(t, n), s.set(n, t);
    var a = e(t, n, i);
    return s.delete(t), s.delete(n), a;
  };
}
function Fe(e) {
  return sr(e).concat(ar(e));
}
var gt = Object.hasOwn || function(e, r) {
  return or.call(e, r);
};
function ae(e, r) {
  return e || r ? e === r : e === r || e !== e && r !== r;
}
var wt = "_owner", Be = Object.getOwnPropertyDescriptor, Ve = Object.keys;
function ur(e, r, t) {
  var n = e.length;
  if (r.length !== n)
    return !1;
  for (; n-- > 0; )
    if (!t.equals(e[n], r[n], n, n, e, r, t))
      return !1;
  return !0;
}
function lr(e, r) {
  return ae(e.getTime(), r.getTime());
}
function Ge(e, r, t) {
  if (e.size !== r.size)
    return !1;
  for (var n = {}, i = e.entries(), s = 0, f, u; (f = i.next()) && !f.done; ) {
    for (var a = r.entries(), l = !1, o = 0; (u = a.next()) && !u.done; ) {
      var d = f.value, h = d[0], m = d[1], c = u.value, p = c[0], v = c[1];
      !l && !n[o] && (l = t.equals(h, p, s, o, e, r, t) && t.equals(m, v, h, p, e, r, t)) && (n[o] = !0), o++;
    }
    if (!l)
      return !1;
    s++;
  }
  return !0;
}
function cr(e, r, t) {
  var n = Ve(e), i = n.length;
  if (Ve(r).length !== i)
    return !1;
  for (var s; i-- > 0; )
    if (s = n[i], s === wt && (e.$$typeof || r.$$typeof) && e.$$typeof !== r.$$typeof || !gt(r, s) || !t.equals(e[s], r[s], s, s, e, r, t))
      return !1;
  return !0;
}
function oe(e, r, t) {
  var n = Fe(e), i = n.length;
  if (Fe(r).length !== i)
    return !1;
  for (var s, f, u; i-- > 0; )
    if (s = n[i], s === wt && (e.$$typeof || r.$$typeof) && e.$$typeof !== r.$$typeof || !gt(r, s) || !t.equals(e[s], r[s], s, s, e, r, t) || (f = Be(e, s), u = Be(r, s), (f || u) && (!f || !u || f.configurable !== u.configurable || f.enumerable !== u.enumerable || f.writable !== u.writable)))
      return !1;
  return !0;
}
function fr(e, r) {
  return ae(e.valueOf(), r.valueOf());
}
function dr(e, r) {
  return e.source === r.source && e.flags === r.flags;
}
function He(e, r, t) {
  if (e.size !== r.size)
    return !1;
  for (var n = {}, i = e.values(), s, f; (s = i.next()) && !s.done; ) {
    for (var u = r.values(), a = !1, l = 0; (f = u.next()) && !f.done; )
      !a && !n[l] && (a = t.equals(s.value, f.value, s.value, f.value, e, r, t)) && (n[l] = !0), l++;
    if (!a)
      return !1;
  }
  return !0;
}
function vr(e, r) {
  var t = e.length;
  if (r.length !== t)
    return !1;
  for (; t-- > 0; )
    if (e[t] !== r[t])
      return !1;
  return !0;
}
var hr = "[object Arguments]", mr = "[object Boolean]", pr = "[object Date]", yr = "[object Map]", gr = "[object Number]", wr = "[object Object]", br = "[object RegExp]", Er = "[object Set]", Tr = "[object String]", Sr = Array.isArray, Ue = typeof ArrayBuffer == "function" && ArrayBuffer.isView ? ArrayBuffer.isView : null, Ye = Object.assign, Or = Object.prototype.toString.call.bind(Object.prototype.toString);
function Cr(e) {
  var r = e.areArraysEqual, t = e.areDatesEqual, n = e.areMapsEqual, i = e.areObjectsEqual, s = e.arePrimitiveWrappersEqual, f = e.areRegExpsEqual, u = e.areSetsEqual, a = e.areTypedArraysEqual;
  return function(o, d, h) {
    if (o === d)
      return !0;
    if (o == null || d == null || typeof o != "object" || typeof d != "object")
      return o !== o && d !== d;
    var m = o.constructor;
    if (m !== d.constructor)
      return !1;
    if (m === Object)
      return i(o, d, h);
    if (Sr(o))
      return r(o, d, h);
    if (Ue != null && Ue(o))
      return a(o, d, h);
    if (m === Date)
      return t(o, d, h);
    if (m === RegExp)
      return f(o, d, h);
    if (m === Map)
      return n(o, d, h);
    if (m === Set)
      return u(o, d, h);
    var c = Or(o);
    return c === pr ? t(o, d, h) : c === br ? f(o, d, h) : c === yr ? n(o, d, h) : c === Er ? u(o, d, h) : c === wr ? typeof o.then != "function" && typeof d.then != "function" && i(o, d, h) : c === hr ? i(o, d, h) : c === mr || c === gr || c === Tr ? s(o, d, h) : !1;
  };
}
function Ir(e) {
  var r = e.circular, t = e.createCustomConfig, n = e.strict, i = {
    areArraysEqual: n ? oe : ur,
    areDatesEqual: lr,
    areMapsEqual: n ? Me(Ge, oe) : Ge,
    areObjectsEqual: n ? oe : cr,
    arePrimitiveWrappersEqual: fr,
    areRegExpsEqual: dr,
    areSetsEqual: n ? Me(He, oe) : He,
    areTypedArraysEqual: n ? oe : vr
  };
  if (t && (i = Ye({}, i, t(i))), r) {
    var s = ye(i.areArraysEqual), f = ye(i.areMapsEqual), u = ye(i.areObjectsEqual), a = ye(i.areSetsEqual);
    i = Ye({}, i, {
      areArraysEqual: s,
      areMapsEqual: f,
      areObjectsEqual: u,
      areSetsEqual: a
    });
  }
  return i;
}
function _r(e) {
  return function(r, t, n, i, s, f, u) {
    return e(r, t, u);
  };
}
function Ar(e) {
  var r = e.circular, t = e.comparator, n = e.createState, i = e.equals, s = e.strict;
  if (n)
    return function(a, l) {
      var o = n(), d = o.cache, h = d === void 0 ? r ? /* @__PURE__ */ new WeakMap() : void 0 : d, m = o.meta;
      return t(a, l, {
        cache: h,
        equals: i,
        meta: m,
        strict: s
      });
    };
  if (r)
    return function(a, l) {
      return t(a, l, {
        cache: /* @__PURE__ */ new WeakMap(),
        equals: i,
        meta: void 0,
        strict: s
      });
    };
  var f = {
    cache: void 0,
    equals: i,
    meta: void 0,
    strict: s
  };
  return function(a, l) {
    return t(a, l, f);
  };
}
var Ce = Y();
Y({ strict: !0 });
Y({ circular: !0 });
Y({
  circular: !0,
  strict: !0
});
Y({
  createInternalComparator: function() {
    return ae;
  }
});
Y({
  strict: !0,
  createInternalComparator: function() {
    return ae;
  }
});
Y({
  circular: !0,
  createInternalComparator: function() {
    return ae;
  }
});
Y({
  circular: !0,
  createInternalComparator: function() {
    return ae;
  },
  strict: !0
});
function Y(e) {
  e === void 0 && (e = {});
  var r = e.circular, t = r === void 0 ? !1 : r, n = e.createInternalComparator, i = e.createState, s = e.strict, f = s === void 0 ? !1 : s, u = Ir(e), a = Cr(u), l = n ? n(a) : _r(a);
  return Ar({ circular: t, comparator: a, createState: i, equals: l, strict: f });
}
function ni(e, r) {
  return yt(e, {
    ...r,
    comparator: Ce
  });
}
function ii(e, r) {
  return yt(e, {
    ...r,
    comparator: (t, n) => {
      if (!n || t.length !== n.length)
        return !1;
      for (let i = 0, s = t.length; i < s; i++)
        if (t[i] !== (n == null ? void 0 : n[i]))
          return !1;
      return !0;
    }
  });
}
function si(e) {
  const r = A(!!e);
  return [r, (n) => {
    const i = q(n), s = q(() => (r.value || (r.value = !0), n()));
    return Bt(s, { bypassed: i });
  }];
}
function qr(e) {
  return typeof e == "boolean" ? !e : Te(e) ? q(() => !e.value) : () => !e();
}
var le = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function ze(e) {
  return e && e.__esModule && Object.prototype.hasOwnProperty.call(e, "default") ? e.default : e;
}
function $r(e) {
  var r = typeof e;
  return e != null && (r == "object" || r == "function");
}
var bt = $r, jr = typeof le == "object" && le && le.Object === Object && le, Pr = jr, xr = Pr, kr = typeof self == "object" && self && self.Object === Object && self, Nr = xr || kr || Function("return this")(), Et = Nr, zr = Et, Lr = function() {
  return zr.Date.now();
}, Wr = Lr, Rr = /\s/;
function Dr(e) {
  for (var r = e.length; r-- && Rr.test(e.charAt(r)); )
    ;
  return r;
}
var Mr = Dr, Fr = Mr, Br = /^\s+/;
function Vr(e) {
  return e && e.slice(0, Fr(e) + 1).replace(Br, "");
}
var Gr = Vr, Hr = Et, Ur = Hr.Symbol, Tt = Ur, Qe = Tt, St = Object.prototype, Yr = St.hasOwnProperty, Qr = St.toString, ue = Qe ? Qe.toStringTag : void 0;
function Xr(e) {
  var r = Yr.call(e, ue), t = e[ue];
  try {
    e[ue] = void 0;
    var n = !0;
  } catch {
  }
  var i = Qr.call(e);
  return n && (r ? e[ue] = t : delete e[ue]), i;
}
var Jr = Xr, Zr = Object.prototype, Kr = Zr.toString;
function en(e) {
  return Kr.call(e);
}
var tn = en, Xe = Tt, rn = Jr, nn = tn, sn = "[object Null]", an = "[object Undefined]", Je = Xe ? Xe.toStringTag : void 0;
function on(e) {
  return e == null ? e === void 0 ? an : sn : Je && Je in Object(e) ? rn(e) : nn(e);
}
var un = on;
function ln(e) {
  return e != null && typeof e == "object";
}
var cn = ln, fn = un, dn = cn, vn = "[object Symbol]";
function hn(e) {
  return typeof e == "symbol" || dn(e) && fn(e) == vn;
}
var mn = hn, pn = Gr, Ze = bt, yn = mn, Ke = NaN, gn = /^[-+]0x[0-9a-f]+$/i, wn = /^0b[01]+$/i, bn = /^0o[0-7]+$/i, En = parseInt;
function Tn(e) {
  if (typeof e == "number")
    return e;
  if (yn(e))
    return Ke;
  if (Ze(e)) {
    var r = typeof e.valueOf == "function" ? e.valueOf() : e;
    e = Ze(r) ? r + "" : r;
  }
  if (typeof e != "string")
    return e === 0 ? e : +e;
  e = pn(e);
  var t = wn.test(e);
  return t || bn.test(e) ? En(e.slice(2), t ? 2 : 8) : gn.test(e) ? Ke : +e;
}
var Sn = Tn, On = bt, _e = Wr, et = Sn, Cn = "Expected a function", In = Math.max, _n = Math.min;
function An(e, r, t) {
  var n, i, s, f, u, a, l = 0, o = !1, d = !1, h = !0;
  if (typeof e != "function")
    throw new TypeError(Cn);
  r = et(r) || 0, On(t) && (o = !!t.leading, d = "maxWait" in t, s = d ? In(et(t.maxWait) || 0, r) : s, h = "trailing" in t ? !!t.trailing : h);
  function m(T) {
    var P = n, k = i;
    return n = i = void 0, l = T, f = e.apply(k, P), f;
  }
  function c(T) {
    return l = T, u = setTimeout(g, r), o ? m(T) : f;
  }
  function p(T) {
    var P = T - a, k = T - l, Le = r - P;
    return d ? _n(Le, s - k) : Le;
  }
  function v(T) {
    var P = T - a, k = T - l;
    return a === void 0 || P >= r || P < 0 || d && k >= s;
  }
  function g() {
    var T = _e();
    if (v(T))
      return w(T);
    u = setTimeout(g, p(T));
  }
  function w(T) {
    return u = void 0, h && n ? m(T) : (n = i = void 0, f);
  }
  function b() {
    u !== void 0 && clearTimeout(u), l = 0, n = a = i = u = void 0;
  }
  function O() {
    return u === void 0 ? f : w(_e());
  }
  function I() {
    var T = _e(), P = v(T);
    if (n = arguments, i = this, a = T, P) {
      if (u === void 0)
        return c(a);
      if (d)
        return clearTimeout(u), u = setTimeout(g, r), m(a);
    }
    return u === void 0 && (u = setTimeout(g, r)), f;
  }
  return I.cancel = b, I.flush = O, I;
}
var qn = An;
const $n = /* @__PURE__ */ ze(qn), Q = {
  year: 365 * 24 * 60 * 60 * 1e3,
  month: 30 * 24 * 60 * 60 * 1e3,
  date: 24 * 60 * 60 * 1e3,
  hours: 60 * 60 * 1e3,
  minutes: 60 * 1e3,
  seconds: 1e3,
  milliseconds: 1
}, jn = (e, r) => ((e.year ?? 0) * Q.year + (e.month ?? 0) * Q.month + (e.date ?? 0) * Q.date + (e.hours ?? 0) * Q.hours + (e.minutes ?? 0) * Q.minutes + (e.seconds ?? 0) * Q.seconds + (e.milliseconds ?? 0)) / Q[r];
function Pn() {
  const e = {
    state: "pending"
  };
  return e.promise = new Promise((r, t) => {
    e.resolve = (n) => {
      e.state = "resolved", r(n);
    }, e.reject = (n) => {
      e.state = "rejected", t(n);
    };
  }), e;
}
function ai(e) {
  const r = Pn(), t = N(e);
  if (t)
    return r.resolve(t), r.promise;
  const n = _(e, (i) => {
    i && (n(), r.resolve(i));
  });
  return r.promise;
}
function oi(e, r, t) {
  return q(
    {
      get() {
        return e[r];
      },
      set(n) {
        e[r] = n;
      }
    },
    t
  );
}
const xn = (e, r, t) => {
  const n = Se(e), i = dt(n, r, t);
  return q({
    get: () => i.value,
    set: (s) => {
      n.value = s;
    }
  });
};
function ui(e, r, t) {
  const n = q({
    get() {
      return e.value;
    },
    set(i) {
      r(i), e.value = i, t == null || t(i);
    }
  });
  return n.bypass = (i) => {
    e.value = i;
  }, n;
}
function li(e, r, t = {}) {
  const {
    debounce: n = 0,
    maxWait: i = void 0,
    ...s
  } = t, { eventFilter: f, pause: u, resume: a, isActive: l } = Ht(Ne(n, { maxWait: i }));
  return { stop: vt(
    e,
    r,
    {
      ...s,
      eventFilter: f
    }
  ), pause: u, resume: a, isActive: l };
}
const ci = (e, r, t) => {
  const n = Object.entries(e), i = n.map(([f]) => f), s = n.map(([, f]) => f);
  return _(
    s,
    (f, u) => {
      const a = f.reduce(
        (o, d, h) => (o[i[h]] = d, o),
        {}
      ), l = u.reduce(
        (o, d, h) => (o[i[h]] = d, o),
        {}
      );
      r(a, l);
    },
    t
  );
}, fi = (e, r, t) => {
  const n = Object.entries(e), i = n.map(([f]) => f), s = n.map(([, f]) => f);
  return pt(
    s,
    (f, u) => {
      const a = f.reduce(
        (o, d, h) => (o[i[h]] = d, o),
        {}
      ), l = u.reduce(
        (o, d, h) => (o[i[h]] = d, o),
        {}
      );
      r(a, l);
    },
    t
  );
};
function kn(e, r, t) {
  const { onlyIf: n, compareDeepEqual: i, ...s } = t ?? {};
  if ((t == null ? void 0 : t.onlyIf) === void 0)
    return _(e, r, t);
  let f;
  function u() {
    if (!f) return;
    const m = f;
    f = void 0, m();
  }
  function a(m) {
    f = m;
  }
  const l = S(!0), o = (m, c) => {
    if (l.value = !1, !(i && Ce(m, c)))
      return u(), r(m, c, a);
  };
  let d;
  const h = () => {
    o(Ot(e), d);
  };
  return _(Se(n), (m) => {
    l.value && m && h();
  }), _(
    e,
    (m, c) => {
      if (d = c, !N(n)) {
        l.value = !0;
        return;
      }
      o(m, c);
    },
    s
  );
}
function Nn(e, r, t) {
  const { onlyIf: n, debounce: i, maxWait: s, compareDeepEqual: f, ...u } = t ?? {};
  if ((t == null ? void 0 : t.onlyIf) === void 0)
    return pt(e, r, t);
  let a;
  function l() {
    if (!a) return;
    const v = a;
    a = void 0, v();
  }
  function o(v) {
    a = v;
  }
  const d = S(!0), h = (v, g) => {
    if (d.value = !1, !(f && Ce(v, g)))
      return l(), r(v, g, o);
  };
  let m;
  _(Se(n), (v) => {
    d.value && v && p();
  });
  const c = q(
    () => $n(
      (v, g) => {
        h(v, g);
      },
      N(i) ?? 0,
      { maxWait: N(s) }
    )
  ), p = () => {
    c.value(Ot(e), m);
  };
  return _(
    e,
    (v, g) => {
      if (m = g, !N(n)) {
        d.value = !0;
        return;
      }
      c.value(v, g);
    },
    u
  );
}
function Ot(e) {
  return Wt(e) ? e : Array.isArray(e) ? e.map((r) => N(r)) : N(e);
}
const di = /* @__PURE__ */ J({
  __name: "LazyRender",
  props: {
    minHeight: {},
    unrenderedClass: { default: void 0 }
  },
  setup(e) {
    const r = A(), t = xn(!1, 100);
    return er(r, ([{ isIntersecting: n }]) => {
      t.value = n;
    }), (n, i) => (fe(), ke(
      "div",
      {
        ref_key: "target",
        ref: r,
        style: F({ "min-height": typeof n.minHeight == "number" ? `${n.minHeight}px` : n.minHeight }),
        class: D(n.unrenderedClass ? { [n.unrenderedClass]: !ce(t) } : {})
      },
      [
        ce(t) ? B(n.$slots, "default", { key: 0 }) : Rt("v-if", !0)
      ],
      6
      /* CLASS, STYLE */
    ));
  }
}), zn = /* @__PURE__ */ J({
  __name: "ScrollShadowContainer",
  props: {
    zIndex: { default: void 0 }
  },
  setup(e) {
    const r = A(null), t = A(null), n = A(void 0), i = A(void 0), s = ge({
      top: !1,
      right: !1,
      bottom: !1,
      left: !1
    });
    async function f() {
      var a, l;
      n.value = void 0, i.value = void 0, await xe(), n.value = (a = r.value) == null ? void 0 : a.clientWidth, i.value = (l = r.value) == null ? void 0 : l.clientHeight;
    }
    function u() {
      const a = r.value;
      if (!a)
        return;
      const l = a.clientWidth < a.scrollWidth, o = a.clientHeight < a.scrollHeight, d = a.offsetWidth + a.scrollLeft, h = a.offsetHeight + a.scrollTop, m = a.scrollTop === 0, c = d >= a.scrollWidth - 2, p = h >= a.scrollHeight - 2, v = a.scrollLeft === 0;
      s.top = o && !m, s.right = l && !c, s.bottom = o && !p, s.left = l && !v;
    }
    return $e(r, () => {
      u();
    }), $e(t, () => {
      f();
    }), (a, l) => (fe(), ke(
      "div",
      {
        ref_key: "el",
        ref: t,
        class: D(a.$style.wrap)
      },
      [
        K(
          "div",
          {
            ref_key: "scrollContainer",
            ref: r,
            class: D(a.$style["scroll-container"]),
            style: F({ width: n.value, height: i.value }),
            onScrollPassive: u
          },
          [
            B(a.$slots, "default"),
            K(
              "span",
              {
                class: D([a.$style["shadow-top"], s.top && a.$style["is-active"]]),
                style: F({ zIndex: a.zIndex })
              },
              null,
              6
              /* CLASS, STYLE */
            ),
            K(
              "span",
              {
                class: D([a.$style["shadow-right"], s.right && a.$style["is-active"]]),
                style: F({ zIndex: a.zIndex })
              },
              null,
              6
              /* CLASS, STYLE */
            ),
            K(
              "span",
              {
                class: D([a.$style["shadow-bottom"], s.bottom && a.$style["is-active"]]),
                style: F({ zIndex: a.zIndex })
              },
              null,
              6
              /* CLASS, STYLE */
            ),
            K(
              "span",
              {
                class: D([a.$style["shadow-left"], s.left && a.$style["is-active"]]),
                style: F({ zIndex: a.zIndex })
              },
              null,
              6
              /* CLASS, STYLE */
            )
          ],
          38
          /* CLASS, STYLE, NEED_HYDRATION */
        )
      ],
      2
      /* CLASS */
    ));
  }
}), Ln = "_wrap_12rlz_1", Wn = {
  wrap: Ln,
  "scroll-container": "_scroll-container_12rlz_6",
  "shadow-top": "_shadow-top_12rlz_10",
  "shadow-right": "_shadow-right_12rlz_11",
  "shadow-bottom": "_shadow-bottom_12rlz_12",
  "shadow-left": "_shadow-left_12rlz_13",
  "is-active": "_is-active_12rlz_59"
}, Rn = (e, r) => {
  const t = e.__vccOpts || e;
  for (const [n, i] of r)
    t[n] = i;
  return t;
}, Dn = {
  $style: Wn
}, vi = /* @__PURE__ */ Rn(zn, [["__cssModules", Dn]]), hi = /* @__PURE__ */ J({
  __name: "StickyContent",
  props: {
    tag: { default: "div" },
    top: { default: 0 },
    bottom: { default: 0 },
    maxHeightScreen: { type: Boolean, default: !1 }
  },
  setup(e) {
    const r = e, t = A(), n = A(), i = ge(rr()), s = ge(
      De(t, {
        windowResize: !0,
        windowScroll: !0
      })
    ), f = ge(
      De(n, {
        windowResize: !0,
        windowScroll: !0
      })
    ), u = ir(
      () => {
        const a = {
          transitionDuration: "75ms",
          height: r.maxHeightScreen ? `${i.height - (s.y > 0 ? s.y : 0) - r.top - r.bottom}px` : void 0,
          overflowY: r.maxHeightScreen ? "auto" : void 0
        }, l = s.y;
        if (l - r.top > 0)
          a.transform = "translateY(0px)";
        else {
          let o = -l + r.top;
          f.height + r.top + r.bottom > s.bottom && (o = s.height - f.height - r.bottom - r.top), a.transform = `translateY(${o < 0 ? 0 : o}px)`;
        }
        return a;
      },
      10,
      { maxWait: 75 }
    );
    return (a, l) => (fe(), at(
      ot(a.tag),
      Dt({
        ref_key: "refContainer",
        ref: t
      }, a.$attrs),
      {
        default: ut(() => [
          K(
            "div",
            {
              ref_key: "refChild",
              ref: n,
              style: F(ce(u))
            },
            [
              B(a.$slots, "default")
            ],
            4
            /* STYLE */
          )
        ]),
        _: 3
        /* FORWARDED */
      },
      16
      /* FULL_PROPS */
    ));
  }
}), mi = /* @__PURE__ */ J({
  __name: "TemplateVars",
  props: {
    vars: {}
  },
  setup(e) {
    return (r, t) => B(r.$slots, "default", Mt(Ft(r.vars)));
  }
}), pi = (e, r) => {
  const t = typeof (r == null ? void 0 : r.interval) == "object" ? jn(r.interval, "milliseconds") : r == null ? void 0 : r.interval, n = tr({ interval: t }), i = S(e(n.value));
  return _(n, () => {
    const s = e(n.value);
    s.getTime() !== i.value.getTime() && (i.value = s);
  }), G(i);
}, yi = () => {
  const e = A(!1);
  return R(() => {
    e.value = !0;
  }), G(e);
}, gi = (e) => {
  const r = S(N(e));
  if (!r.value) {
    const t = _(
      () => !!N(e) && !r.value,
      (n) => {
        n && (r.value = !0, t());
      }
    );
  }
  return G(r);
};
var Ct = { exports: {} };
/* NProgress, (c) 2013, 2014 Rico Sta. Cruz - http://ricostacruz.com/nprogress
 * @license MIT */
(function(e, r) {
  (function(t, n) {
    e.exports = n();
  })(le, function() {
    var t = {};
    t.version = "0.2.0";
    var n = t.settings = {
      minimum: 0.08,
      easing: "ease",
      positionUsing: "",
      speed: 200,
      trickle: !0,
      trickleRate: 0.02,
      trickleSpeed: 800,
      showSpinner: !0,
      barSelector: '[role="bar"]',
      spinnerSelector: '[role="spinner"]',
      parent: "body",
      template: '<div class="bar" role="bar"><div class="peg"></div></div><div class="spinner" role="spinner"><div class="spinner-icon"></div></div>'
    };
    t.configure = function(c) {
      var p, v;
      for (p in c)
        v = c[p], v !== void 0 && c.hasOwnProperty(p) && (n[p] = v);
      return this;
    }, t.status = null, t.set = function(c) {
      var p = t.isStarted();
      c = i(c, n.minimum, 1), t.status = c === 1 ? null : c;
      var v = t.render(!p), g = v.querySelector(n.barSelector), w = n.speed, b = n.easing;
      return v.offsetWidth, u(function(O) {
        n.positionUsing === "" && (n.positionUsing = t.getPositioningCSS()), a(g, f(c, w, b)), c === 1 ? (a(v, {
          transition: "none",
          opacity: 1
        }), v.offsetWidth, setTimeout(function() {
          a(v, {
            transition: "all " + w + "ms linear",
            opacity: 0
          }), setTimeout(function() {
            t.remove(), O();
          }, w);
        }, w)) : setTimeout(O, w);
      }), this;
    }, t.isStarted = function() {
      return typeof t.status == "number";
    }, t.start = function() {
      t.status || t.set(0);
      var c = function() {
        setTimeout(function() {
          t.status && (t.trickle(), c());
        }, n.trickleSpeed);
      };
      return n.trickle && c(), this;
    }, t.done = function(c) {
      return !c && !t.status ? this : t.inc(0.3 + 0.5 * Math.random()).set(1);
    }, t.inc = function(c) {
      var p = t.status;
      return p ? (typeof c != "number" && (c = (1 - p) * i(Math.random() * p, 0.1, 0.95)), p = i(p + c, 0, 0.994), t.set(p)) : t.start();
    }, t.trickle = function() {
      return t.inc(Math.random() * n.trickleRate);
    }, function() {
      var c = 0, p = 0;
      t.promise = function(v) {
        return !v || v.state() === "resolved" ? this : (p === 0 && t.start(), c++, p++, v.always(function() {
          p--, p === 0 ? (c = 0, t.done()) : t.set((c - p) / c);
        }), this);
      };
    }(), t.render = function(c) {
      if (t.isRendered()) return document.getElementById("nprogress");
      o(document.documentElement, "nprogress-busy");
      var p = document.createElement("div");
      p.id = "nprogress", p.innerHTML = n.template;
      var v = p.querySelector(n.barSelector), g = c ? "-100" : s(t.status || 0), w = document.querySelector(n.parent), b;
      return a(v, {
        transition: "all 0 linear",
        transform: "translate3d(" + g + "%,0,0)"
      }), n.showSpinner || (b = p.querySelector(n.spinnerSelector), b && m(b)), w != document.body && o(w, "nprogress-custom-parent"), w.appendChild(p), p;
    }, t.remove = function() {
      d(document.documentElement, "nprogress-busy"), d(document.querySelector(n.parent), "nprogress-custom-parent");
      var c = document.getElementById("nprogress");
      c && m(c);
    }, t.isRendered = function() {
      return !!document.getElementById("nprogress");
    }, t.getPositioningCSS = function() {
      var c = document.body.style, p = "WebkitTransform" in c ? "Webkit" : "MozTransform" in c ? "Moz" : "msTransform" in c ? "ms" : "OTransform" in c ? "O" : "";
      return p + "Perspective" in c ? "translate3d" : p + "Transform" in c ? "translate" : "margin";
    };
    function i(c, p, v) {
      return c < p ? p : c > v ? v : c;
    }
    function s(c) {
      return (-1 + c) * 100;
    }
    function f(c, p, v) {
      var g;
      return n.positionUsing === "translate3d" ? g = { transform: "translate3d(" + s(c) + "%,0,0)" } : n.positionUsing === "translate" ? g = { transform: "translate(" + s(c) + "%,0)" } : g = { "margin-left": s(c) + "%" }, g.transition = "all " + p + "ms " + v, g;
    }
    var u = /* @__PURE__ */ function() {
      var c = [];
      function p() {
        var v = c.shift();
        v && v(p);
      }
      return function(v) {
        c.push(v), c.length == 1 && p();
      };
    }(), a = /* @__PURE__ */ function() {
      var c = ["Webkit", "O", "Moz", "ms"], p = {};
      function v(O) {
        return O.replace(/^-ms-/, "ms-").replace(/-([\da-z])/gi, function(I, T) {
          return T.toUpperCase();
        });
      }
      function g(O) {
        var I = document.body.style;
        if (O in I) return O;
        for (var T = c.length, P = O.charAt(0).toUpperCase() + O.slice(1), k; T--; )
          if (k = c[T] + P, k in I) return k;
        return O;
      }
      function w(O) {
        return O = v(O), p[O] || (p[O] = g(O));
      }
      function b(O, I, T) {
        I = w(I), O.style[I] = T;
      }
      return function(O, I) {
        var T = arguments, P, k;
        if (T.length == 2)
          for (P in I)
            k = I[P], k !== void 0 && I.hasOwnProperty(P) && b(O, P, k);
        else
          b(O, T[1], T[2]);
      };
    }();
    function l(c, p) {
      var v = typeof c == "string" ? c : h(c);
      return v.indexOf(" " + p + " ") >= 0;
    }
    function o(c, p) {
      var v = h(c), g = v + p;
      l(v, p) || (c.className = g.substring(1));
    }
    function d(c, p) {
      var v = h(c), g;
      l(c, p) && (g = v.replace(" " + p + " ", " "), c.className = g.substring(1, g.length - 1));
    }
    function h(c) {
      return (" " + (c.className || "") + " ").replace(/\s+/gi, " ");
    }
    function m(c) {
      c && c.parentNode && c.parentNode.removeChild(c);
    }
    return t;
  });
})(Ct);
var Mn = Ct.exports;
const ee = /* @__PURE__ */ ze(Mn);
function Fn(e = { clearErrorOnNewExecute: !0 }) {
  const r = S(!1), t = A(null), n = A(null);
  let i = null;
  return {
    isLoading: r,
    hasError: t,
    result: n,
    fn: (f) => (...u) => i || (r.value = !0, e.useNProgress && ee.start(), i = f(...u), e.clearErrorOnNewExecute && (t.value = null), e.clearOnNewExecute && (n.value = null), i.then((a) => {
      var l;
      n.value = a, (l = e.onResult) == null || l.call(e, a, ...u);
    }), i.catch((a) => {
      var l;
      t.value = a, (l = e.onError) == null || l.call(e, a, ...u);
    }), i.finally(() => {
      var a;
      e.useNProgress && ee.done(), i = null, r.value = !1, (a = e.onFinally) == null || a.call(e, ...u);
    }), i)
  };
}
function wi(e, r = { clearErrorOnNewExecute: !0 }) {
  const t = Fn(r), n = t.fn(e);
  return {
    ...t,
    execute: n
  };
}
var It = { exports: {} };
(function(e) {
  var r = Object.prototype.hasOwnProperty, t = "~";
  function n() {
  }
  Object.create && (n.prototype = /* @__PURE__ */ Object.create(null), new n().__proto__ || (t = !1));
  function i(a, l, o) {
    this.fn = a, this.context = l, this.once = o || !1;
  }
  function s(a, l, o, d, h) {
    if (typeof o != "function")
      throw new TypeError("The listener must be a function");
    var m = new i(o, d || a, h), c = t ? t + l : l;
    return a._events[c] ? a._events[c].fn ? a._events[c] = [a._events[c], m] : a._events[c].push(m) : (a._events[c] = m, a._eventsCount++), a;
  }
  function f(a, l) {
    --a._eventsCount === 0 ? a._events = new n() : delete a._events[l];
  }
  function u() {
    this._events = new n(), this._eventsCount = 0;
  }
  u.prototype.eventNames = function() {
    var l = [], o, d;
    if (this._eventsCount === 0) return l;
    for (d in o = this._events)
      r.call(o, d) && l.push(t ? d.slice(1) : d);
    return Object.getOwnPropertySymbols ? l.concat(Object.getOwnPropertySymbols(o)) : l;
  }, u.prototype.listeners = function(l) {
    var o = t ? t + l : l, d = this._events[o];
    if (!d) return [];
    if (d.fn) return [d.fn];
    for (var h = 0, m = d.length, c = new Array(m); h < m; h++)
      c[h] = d[h].fn;
    return c;
  }, u.prototype.listenerCount = function(l) {
    var o = t ? t + l : l, d = this._events[o];
    return d ? d.fn ? 1 : d.length : 0;
  }, u.prototype.emit = function(l, o, d, h, m, c) {
    var p = t ? t + l : l;
    if (!this._events[p]) return !1;
    var v = this._events[p], g = arguments.length, w, b;
    if (v.fn) {
      switch (v.once && this.removeListener(l, v.fn, void 0, !0), g) {
        case 1:
          return v.fn.call(v.context), !0;
        case 2:
          return v.fn.call(v.context, o), !0;
        case 3:
          return v.fn.call(v.context, o, d), !0;
        case 4:
          return v.fn.call(v.context, o, d, h), !0;
        case 5:
          return v.fn.call(v.context, o, d, h, m), !0;
        case 6:
          return v.fn.call(v.context, o, d, h, m, c), !0;
      }
      for (b = 1, w = new Array(g - 1); b < g; b++)
        w[b - 1] = arguments[b];
      v.fn.apply(v.context, w);
    } else {
      var O = v.length, I;
      for (b = 0; b < O; b++)
        switch (v[b].once && this.removeListener(l, v[b].fn, void 0, !0), g) {
          case 1:
            v[b].fn.call(v[b].context);
            break;
          case 2:
            v[b].fn.call(v[b].context, o);
            break;
          case 3:
            v[b].fn.call(v[b].context, o, d);
            break;
          case 4:
            v[b].fn.call(v[b].context, o, d, h);
            break;
          default:
            if (!w) for (I = 1, w = new Array(g - 1); I < g; I++)
              w[I - 1] = arguments[I];
            v[b].fn.apply(v[b].context, w);
        }
    }
    return !0;
  }, u.prototype.on = function(l, o, d) {
    return s(this, l, o, d, !1);
  }, u.prototype.once = function(l, o, d) {
    return s(this, l, o, d, !0);
  }, u.prototype.removeListener = function(l, o, d, h) {
    var m = t ? t + l : l;
    if (!this._events[m]) return this;
    if (!o)
      return f(this, m), this;
    var c = this._events[m];
    if (c.fn)
      c.fn === o && (!h || c.once) && (!d || c.context === d) && f(this, m);
    else {
      for (var p = 0, v = [], g = c.length; p < g; p++)
        (c[p].fn !== o || h && !c[p].once || d && c[p].context !== d) && v.push(c[p]);
      v.length ? this._events[m] = v.length === 1 ? v[0] : v : f(this, m);
    }
    return this;
  }, u.prototype.removeAllListeners = function(l) {
    var o;
    return l ? (o = t ? t + l : l, this._events[o] && f(this, o)) : (this._events = new n(), this._eventsCount = 0), this;
  }, u.prototype.off = u.prototype.removeListener, u.prototype.addListener = u.prototype.on, u.prefixed = t, u.EventEmitter = u, e.exports = u;
})(It);
var Bn = It.exports;
const Vn = /* @__PURE__ */ ze(Bn);
class _t extends Error {
  constructor(r) {
    super(r), this.name = "TimeoutError";
  }
}
class Gn extends Error {
  constructor(r) {
    super(), this.name = "AbortError", this.message = r;
  }
}
const tt = (e) => globalThis.DOMException === void 0 ? new Gn(e) : new DOMException(e), rt = (e) => {
  const r = e.reason === void 0 ? tt("This operation was aborted.") : e.reason;
  return r instanceof Error ? r : tt(r);
};
function Hn(e, r) {
  const {
    milliseconds: t,
    fallback: n,
    message: i,
    customTimers: s = { setTimeout, clearTimeout }
  } = r;
  let f;
  const a = new Promise((l, o) => {
    if (typeof t != "number" || Math.sign(t) !== 1)
      throw new TypeError(`Expected \`milliseconds\` to be a positive number, got \`${t}\``);
    if (r.signal) {
      const { signal: h } = r;
      h.aborted && o(rt(h)), h.addEventListener("abort", () => {
        o(rt(h));
      });
    }
    if (t === Number.POSITIVE_INFINITY) {
      e.then(l, o);
      return;
    }
    const d = new _t();
    f = s.setTimeout.call(void 0, () => {
      if (n) {
        try {
          l(n());
        } catch (h) {
          o(h);
        }
        return;
      }
      typeof e.cancel == "function" && e.cancel(), i === !1 ? l() : i instanceof Error ? o(i) : (d.message = i ?? `Promise timed out after ${t} milliseconds`, o(d));
    }, t), (async () => {
      try {
        l(await e);
      } catch (h) {
        o(h);
      }
    })();
  }).finally(() => {
    a.clear();
  });
  return a.clear = () => {
    s.clearTimeout.call(void 0, f), f = void 0;
  }, a;
}
function Un(e, r, t) {
  let n = 0, i = e.length;
  for (; i > 0; ) {
    const s = Math.trunc(i / 2);
    let f = n + s;
    t(e[f], r) <= 0 ? (n = ++f, i -= s + 1) : i = s;
  }
  return n;
}
var W;
class Yn {
  constructor() {
    $(this, W, []);
  }
  enqueue(r, t) {
    t = {
      priority: 0,
      ...t
    };
    const n = {
      priority: t.priority,
      run: r
    };
    if (this.size && y(this, W)[this.size - 1].priority >= t.priority) {
      y(this, W).push(n);
      return;
    }
    const i = Un(y(this, W), n, (s, f) => f.priority - s.priority);
    y(this, W).splice(i, 0, n);
  }
  dequeue() {
    const r = y(this, W).shift();
    return r == null ? void 0 : r.run;
  }
  filter(r) {
    return y(this, W).filter((t) => t.priority === r.priority).map((t) => t.run);
  }
  get size() {
    return y(this, W).length;
  }
}
W = new WeakMap();
var te, re, U, de, ne, ve, z, ie, x, he, L, se, M, me, E, At, qt, $t, jt, Pt, we, je, Pe, be, xt, Ee;
class Qn extends Vn {
  // TODO: The `throwOnTimeout` option should affect the return types of `add()` and `addAll()`
  constructor(t) {
    var n, i;
    super();
    $(this, E);
    $(this, te);
    $(this, re);
    $(this, U, 0);
    $(this, de);
    $(this, ne);
    $(this, ve, 0);
    $(this, z);
    $(this, ie);
    $(this, x);
    $(this, he);
    $(this, L, 0);
    // The `!` is needed because of https://github.com/microsoft/TypeScript/issues/32194
    $(this, se);
    $(this, M);
    $(this, me);
    /**
        Per-operation timeout in milliseconds. Operations fulfill once `timeout` elapses if they haven't already.
    
        Applies to each future operation.
        */
    Re(this, "timeout");
    if (t = {
      carryoverConcurrencyCount: !1,
      intervalCap: Number.POSITIVE_INFINITY,
      interval: 0,
      concurrency: Number.POSITIVE_INFINITY,
      autoStart: !0,
      queueClass: Yn,
      ...t
    }, !(typeof t.intervalCap == "number" && t.intervalCap >= 1))
      throw new TypeError(`Expected \`intervalCap\` to be a number from 1 and up, got \`${((n = t.intervalCap) == null ? void 0 : n.toString()) ?? ""}\` (${typeof t.intervalCap})`);
    if (t.interval === void 0 || !(Number.isFinite(t.interval) && t.interval >= 0))
      throw new TypeError(`Expected \`interval\` to be a finite number >= 0, got \`${((i = t.interval) == null ? void 0 : i.toString()) ?? ""}\` (${typeof t.interval})`);
    C(this, te, t.carryoverConcurrencyCount), C(this, re, t.intervalCap === Number.POSITIVE_INFINITY || t.interval === 0), C(this, de, t.intervalCap), C(this, ne, t.interval), C(this, x, new t.queueClass()), C(this, he, t.queueClass), this.concurrency = t.concurrency, this.timeout = t.timeout, C(this, me, t.throwOnTimeout === !0), C(this, M, t.autoStart === !1);
  }
  get concurrency() {
    return y(this, se);
  }
  set concurrency(t) {
    if (!(typeof t == "number" && t >= 1))
      throw new TypeError(`Expected \`concurrency\` to be a number from 1 and up, got \`${t}\` (${typeof t})`);
    C(this, se, t), j(this, E, be).call(this);
  }
  async add(t, n = {}) {
    return n = {
      timeout: this.timeout,
      throwOnTimeout: y(this, me),
      ...n
    }, new Promise((i, s) => {
      y(this, x).enqueue(async () => {
        var f;
        pe(this, L)._++, pe(this, U)._++;
        try {
          (f = n.signal) == null || f.throwIfAborted();
          let u = t({ signal: n.signal });
          n.timeout && (u = Hn(Promise.resolve(u), { milliseconds: n.timeout })), n.signal && (u = Promise.race([u, j(this, E, xt).call(this, n.signal)]));
          const a = await u;
          i(a), this.emit("completed", a);
        } catch (u) {
          if (u instanceof _t && !n.throwOnTimeout) {
            i();
            return;
          }
          s(u), this.emit("error", u);
        } finally {
          j(this, E, $t).call(this);
        }
      }, n), this.emit("add"), j(this, E, we).call(this);
    });
  }
  async addAll(t, n) {
    return Promise.all(t.map(async (i) => this.add(i, n)));
  }
  /**
  Start (or resume) executing enqueued tasks within concurrency limit. No need to call this if queue is not paused (via `options.autoStart = false` or by `.pause()` method.)
  */
  start() {
    return y(this, M) ? (C(this, M, !1), j(this, E, be).call(this), this) : this;
  }
  /**
  Put queue execution on hold.
  */
  pause() {
    C(this, M, !0);
  }
  /**
  Clear the queue.
  */
  clear() {
    C(this, x, new (y(this, he))());
  }
  /**
      Can be called multiple times. Useful if you for example add additional items at a later time.
  
      @returns A promise that settles when the queue becomes empty.
      */
  async onEmpty() {
    y(this, x).size !== 0 && await j(this, E, Ee).call(this, "empty");
  }
  /**
      @returns A promise that settles when the queue size is less than the given limit: `queue.size < limit`.
  
      If you want to avoid having the queue grow beyond a certain size you can `await queue.onSizeLessThan()` before adding a new item.
  
      Note that this only limits the number of items waiting to start. There could still be up to `concurrency` jobs already running that this call does not include in its calculation.
      */
  async onSizeLessThan(t) {
    y(this, x).size < t || await j(this, E, Ee).call(this, "next", () => y(this, x).size < t);
  }
  /**
      The difference with `.onEmpty` is that `.onIdle` guarantees that all work from the queue has finished. `.onEmpty` merely signals that the queue is empty, but it could mean that some promises haven't completed yet.
  
      @returns A promise that settles when the queue becomes empty, and all promises have completed; `queue.size === 0 && queue.pending === 0`.
      */
  async onIdle() {
    y(this, L) === 0 && y(this, x).size === 0 || await j(this, E, Ee).call(this, "idle");
  }
  /**
  Size of the queue, the number of queued items waiting to run.
  */
  get size() {
    return y(this, x).size;
  }
  /**
      Size of the queue, filtered by the given options.
  
      For example, this can be used to find the number of items remaining in the queue with a specific priority level.
      */
  sizeBy(t) {
    return y(this, x).filter(t).length;
  }
  /**
  Number of running items (no longer in the queue).
  */
  get pending() {
    return y(this, L);
  }
  /**
  Whether the queue is currently paused.
  */
  get isPaused() {
    return y(this, M);
  }
}
te = new WeakMap(), re = new WeakMap(), U = new WeakMap(), de = new WeakMap(), ne = new WeakMap(), ve = new WeakMap(), z = new WeakMap(), ie = new WeakMap(), x = new WeakMap(), he = new WeakMap(), L = new WeakMap(), se = new WeakMap(), M = new WeakMap(), me = new WeakMap(), E = new WeakSet(), At = function() {
  return y(this, re) || y(this, U) < y(this, de);
}, qt = function() {
  return y(this, L) < y(this, se);
}, $t = function() {
  pe(this, L)._--, j(this, E, we).call(this), this.emit("next");
}, jt = function() {
  j(this, E, Pe).call(this), j(this, E, je).call(this), C(this, ie, void 0);
}, Pt = function() {
  const t = Date.now();
  if (y(this, z) === void 0) {
    const n = y(this, ve) - t;
    if (n < 0)
      C(this, U, y(this, te) ? y(this, L) : 0);
    else
      return y(this, ie) === void 0 && C(this, ie, setTimeout(() => {
        j(this, E, jt).call(this);
      }, n)), !0;
  }
  return !1;
}, we = function() {
  if (y(this, x).size === 0)
    return y(this, z) && clearInterval(y(this, z)), C(this, z, void 0), this.emit("empty"), y(this, L) === 0 && this.emit("idle"), !1;
  if (!y(this, M)) {
    const t = !y(this, E, Pt);
    if (y(this, E, At) && y(this, E, qt)) {
      const n = y(this, x).dequeue();
      return n ? (this.emit("active"), n(), t && j(this, E, je).call(this), !0) : !1;
    }
  }
  return !1;
}, je = function() {
  y(this, re) || y(this, z) !== void 0 || (C(this, z, setInterval(() => {
    j(this, E, Pe).call(this);
  }, y(this, ne))), C(this, ve, Date.now() + y(this, ne)));
}, Pe = function() {
  y(this, U) === 0 && y(this, L) === 0 && y(this, z) && (clearInterval(y(this, z)), C(this, z, void 0)), C(this, U, y(this, te) ? y(this, L) : 0), j(this, E, be).call(this);
}, /**
Executes all queued functions until it reaches the limit.
*/
be = function() {
  for (; j(this, E, we).call(this); )
    ;
}, xt = async function(t) {
  return new Promise((n, i) => {
    t.addEventListener("abort", () => {
      i(t.reason);
    }, { once: !0 });
  });
}, Ee = async function(t, n) {
  return new Promise((i) => {
    const s = () => {
      n && !n() || (this.off(t, s), i());
    };
    this.on(t, s);
  });
};
function bi(e, r = {}) {
  const t = new Qn({ concurrency: 1 }), n = A(!0), i = A(!1), s = A(null), f = A(null);
  t.on("idle", () => {
    i.value = !1, r.useNProgress && ee.done();
  }).on("add", () => {
    i.value = !0, r.useNProgress && ee.start();
  }).on("next", () => {
    t.size !== 0 && r.useNProgress && ee.inc();
  });
  function u(...a) {
    return t.add(async () => {
      try {
        const l = await e(...a);
        return f.value = l, n.value = !1, l;
      } catch (l) {
        throw t.size === 0 && r.useNProgress && ee.done(), s.value = l, l;
      }
    });
  }
  return R(() => {
    t.clear(), t.removeAllListeners();
  }), {
    call: u,
    result: f,
    hasError: s,
    isLoading: G(i),
    isPending: n
  };
}
const Xn = {
  s: 1e3,
  m: 1e3 * 60,
  h: 1e3 * 60 * 60,
  d: 1e3 * 60 * 60 * 24,
  w: 1e3 * 60 * 60 * 24 * 7,
  M: 1e3 * 60 * 60 * 24 * 30,
  y: 1e3 * 60 * 60 * 24 * 30 * 12
}, Ei = (e, r) => {
  const t = () => e(/* @__PURE__ */ new Date()), n = S(t()), i = Xn[r.unit], s = Date.now(), u = e(new Date(s + i)).getTime() - s, a = S(u <= 0 ? i : u);
  return mt(() => {
    n.value = t(), a.value !== i && (a.value = i);
  }, a), G(n);
};
function Ti({
  items: e,
  action: r,
  debounce: t = 0,
  requestedItems: n,
  haveLoadedItems: i,
  localOnly: s = !1,
  watchOnlyIf: f,
  watchReset: u
}) {
  const a = n || S([]), l = i || S([]), o = q(() => N(f) !== !1 && !N(s)), d = !o.value, h = A(!d), m = A(N(s));
  function c() {
    a.value = [], l.value = [], h.value = !1, m.value = e.value.length === 0;
  }
  async function p() {
    if (!e.value.length) {
      m.value = !0, h.value = !1;
      return;
    }
    const v = a.value, g = l.value, w = e.value.filter((b) => !v.find((O) => Ce(b, O)));
    if (!w.length) {
      m.value || (m.value = !0), h.value = !1;
      return;
    }
    v.push(...w);
    try {
      h.value = !0, await r(w), await xe();
    } finally {
      h.value && (h.value = !1), m.value || (m.value = !0);
    }
    g.push(...w);
  }
  return Nn(e, p, {
    onlyIf: o,
    debounce: t,
    immediate: !d,
    deep: !0
  }), u && kn(
    u,
    async () => {
      c(), await p();
    },
    {
      onlyIf: qr(s)
    }
  ), {
    requestedItems: a,
    isPending: G(h),
    hasLoaded: G(m),
    reset: c
  };
}
export {
  Kn as VuetilsAsyncComputed,
  ei as VuetilsConditionalLink,
  ti as VuetilsDeclarativeWatch,
  di as VuetilsLazyRender,
  vi as VuetilsScrollShadowContainer,
  hi as VuetilsStickyContent,
  mi as VuetilsTemplateVars,
  ni as computedCompareDeepEqual,
  ii as computedCompareShallowArray,
  ir as computedDebounced,
  ni as computedDeepEqual,
  ri as computedOnlyIf,
  yt as computedWithComparator,
  si as defineComputedLazy,
  qr as negate,
  ai as promiseWatch,
  oi as reactiveValueToRef,
  xn as refDebounced,
  ui as refSetTrap,
  pi as useDateWithNow,
  yi as useDisposed,
  gi as useLazyShow,
  wi as useMutexAction,
  bi as useQueuedAction,
  Ei as useReactiveDate,
  Ti as useRequestOnce,
  Fn as useWrapAction,
  li as watchDebouncedPausable,
  ci as watchMultiAsObject,
  fi as watchMultiAsObjectDebounced,
  kn as watchOnlyIf,
  Nn as watchOnlyIfDebounced
};
