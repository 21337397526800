import { AssignmentEvent } from "@artesa/shared";
import { defineFeathersPiniaStore } from "../../base-store";
import { makeTransformDatesHook } from "../../hooks/transformDates";

export const useAssignmentEventStore = defineFeathersPiniaStore({
  schema: AssignmentEvent,
  servicePath: "assignment-events",
  setupHook({ app, service, transformDates }) {
    const transformDatesHook = makeTransformDatesHook(transformDates);
    service.hooks({
      after: {
        find: [transformDatesHook],
        get: [transformDatesHook],
        create: [transformDatesHook],
        update: [transformDatesHook],
        patch: [transformDatesHook],
        remove: [transformDatesHook],
      },
    });

    app.service("assignment-events-last-completed").hooks({
      after: {
        find: [transformDatesHook],
      },
    });
  },
});

export default useAssignmentEventStore;
