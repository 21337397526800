import { Type, Value } from "../typebox/index.js";
import { uiScope } from "./custom-forms-shared.js";
export const uiSelectUserInput = Type.Object(
  {
    type: Type.Literal("SelectUser"),
    scope: uiScope,
    title: Type.Optional(Type.String()),
    description: Type.Optional(Type.String()),
    labelClass: Type.Optional(Type.String()),
    labelStyle: Type.Optional(Type.String()),
    labelWidth: Type.Optional(Type.String()),
    class: Type.Optional(Type.String()),
    containerClass: Type.Optional(Type.String()),
    containerStyle: Type.Optional(Type.String()),
    default: Type.Optional(Type.String()),
    options: Type.Optional(
      Type.Object({
        placeholder: Type.Optional(Type.String()),
        clearable: Type.Optional(Type.Boolean())
      })
    )
  },
  { $id: "/schemas/form-template/select-user", additionalProperties: false }
);
export function isUiSelectUserInput(value) {
  return value instanceof Object && "type" in value && value["type"] === "SelectUser";
}
export const uiSelectAssignmentTypeInput = Type.Object(
  {
    type: Type.Literal("SelectAssignmentType"),
    scope: uiScope,
    title: Type.Optional(Type.String()),
    description: Type.Optional(Type.String()),
    labelClass: Type.Optional(Type.String()),
    labelStyle: Type.Optional(Type.String()),
    class: Type.Optional(Type.String()),
    containerClass: Type.Optional(Type.String()),
    containerStyle: Type.Optional(Type.String()),
    default: Type.Optional(Type.String()),
    options: Type.Optional(
      Type.Object({
        placeholder: Type.Optional(Type.String()),
        clearable: Type.Optional(Type.Boolean())
      })
    )
  },
  { $id: "/schemas/form-template/select-assignment-type", additionalProperties: false }
);
export function isUiSelectAssignmentTypeInput(value) {
  return Value.Check(uiSelectAssignmentTypeInput, value);
}
export const uiSelectProjectInput = Type.Object(
  {
    type: Type.Literal("SelectProject"),
    scope: uiScope,
    title: Type.Optional(Type.String()),
    description: Type.Optional(Type.String()),
    labelClass: Type.Optional(Type.String()),
    labelStyle: Type.Optional(Type.String()),
    class: Type.Optional(Type.String()),
    containerClass: Type.Optional(Type.String()),
    containerStyle: Type.Optional(Type.String()),
    default: Type.Optional(Type.String()),
    options: Type.Optional(
      Type.Object({
        placeholder: Type.Optional(Type.String()),
        clearable: Type.Optional(Type.Boolean())
      })
    )
  },
  { $id: "/schemas/form-template/select-project", additionalProperties: false }
);
export function isUiSelectProjectInput(value) {
  return value instanceof Object && "type" in value && value["type"] === "SelectProject";
}
export const uiSelectAssignmentInput = Type.Object(
  {
    type: Type.Literal("SelectAssignment"),
    scope: uiScope,
    title: Type.Optional(Type.String()),
    description: Type.Optional(Type.String()),
    labelClass: Type.Optional(Type.String()),
    labelStyle: Type.Optional(Type.String()),
    class: Type.Optional(Type.String()),
    containerClass: Type.Optional(Type.String()),
    containerStyle: Type.Optional(Type.String()),
    default: Type.Optional(Type.String()),
    options: Type.Optional(
      Type.Object({
        placeholder: Type.Optional(Type.String()),
        clearable: Type.Optional(Type.Boolean())
      })
    )
  },
  { $id: "/schemas/form-template/select-assignment", additionalProperties: false }
);
export function isUiSelectAssignmentInput(value) {
  return value instanceof Object && "type" in value && value["type"] === "SelectAssignment";
}
export const uiSelectAssignmentEventTypeInput = Type.Object(
  {
    type: Type.Literal("SelectAssignmentEventType"),
    scope: uiScope,
    title: Type.Optional(Type.String()),
    description: Type.Optional(Type.String()),
    labelClass: Type.Optional(Type.String()),
    labelStyle: Type.Optional(Type.String()),
    class: Type.Optional(Type.String()),
    containerClass: Type.Optional(Type.String()),
    containerStyle: Type.Optional(Type.String()),
    default: Type.Optional(Type.String()),
    options: Type.Optional(
      Type.Object({
        placeholder: Type.Optional(Type.String()),
        clearable: Type.Optional(Type.Boolean())
      })
    )
  },
  { $id: "/schemas/form-template/select-assignment-event-type", additionalProperties: false }
);
export function isUiSelectAssignmentEventTypeInput(value) {
  return value instanceof Object && "type" in value && value["type"] === "SelectAssignmentEventType";
}
export const uiSelectCustomerInput = Type.Object(
  {
    type: Type.Literal("SelectCustomer"),
    scope: uiScope,
    title: Type.Optional(Type.String()),
    description: Type.Optional(Type.String()),
    labelClass: Type.Optional(Type.String()),
    labelStyle: Type.Optional(Type.String()),
    class: Type.Optional(Type.String()),
    containerClass: Type.Optional(Type.String()),
    containerStyle: Type.Optional(Type.String()),
    default: Type.Optional(Type.String()),
    options: Type.Optional(
      Type.Object({
        placeholder: Type.Optional(Type.String()),
        clearable: Type.Optional(Type.Boolean())
      })
    )
  },
  { $id: "/schemas/form-template/select-customer", additionalProperties: false }
);
export function isUiSelectCustomerInput(value) {
  return value instanceof Object && "type" in value && value["type"] === "SelectCustomer";
}
export const uiCardCustomer = Type.Object(
  {
    type: Type.Literal("CardCustomer"),
    scope: uiScope,
    title: Type.Optional(Type.String()),
    description: Type.Optional(Type.String()),
    labelClass: Type.Optional(Type.String()),
    labelStyle: Type.Optional(Type.String()),
    class: Type.Optional(Type.String()),
    containerClass: Type.Optional(Type.String()),
    containerStyle: Type.Optional(Type.String()),
    default: Type.Optional(Type.String()),
    options: Type.Optional(
      Type.Object({
        withAddress: Type.Optional(Type.Boolean())
      })
    )
  },
  { $id: "/schemas/form-template/card-customer", additionalProperties: false }
);
export function isUiCardCustomer(value) {
  return value instanceof Object && "type" in value && value["type"] === "CardCustomer";
}
export const uiCardAddress = Type.Object(
  {
    type: Type.Literal("CardAddress"),
    scope: uiScope,
    title: Type.Optional(Type.String()),
    description: Type.Optional(Type.String()),
    labelClass: Type.Optional(Type.String()),
    labelStyle: Type.Optional(Type.String()),
    class: Type.Optional(Type.String()),
    containerClass: Type.Optional(Type.String()),
    containerStyle: Type.Optional(Type.String()),
    default: Type.Optional(Type.String()),
    options: Type.Optional(Type.Object({}))
  },
  { $id: "/schemas/form-template/card-address", additionalProperties: false }
);
export function isUiCardAddress(value) {
  return value instanceof Object && "type" in value && value["type"] === "CardAddress";
}
