//const defaultTheme = require("tailwindcss/defaultTheme");
import colors from "tailwindcss/colors";
import containerQueries from "@tailwindcss/container-queries";
import plugin from "tailwindcss/plugin";
import { type Config } from "tailwindcss";

export const breakpoints = {
  xs: 460,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1560,
  "2xl": 1920,
} satisfies Record<string, number>;

const breakpointsPixels: Record<string, string> = {};

for (const key in breakpoints) {
  breakpointsPixels[key] = `${breakpoints[key]}px`;
}

const slate = {
  50: "#f8fafc",
  100: "#f1f5f9",
  200: "#e2e8f0",
  300: "#cbd5e1",
  400: "#94a3b8",
  450: "#7C8CA3", // 👈 custom
  500: "#64748b",
  600: "#475569",
  700: "#334155",
  800: "#1e293b",
  900: "#0f172a",
  950: "#020617",
};

const primaryDefault = "#007c7f";

const primary = {
  DEFAULT: primaryDefault,
  50: "#EEFFFD",
  100: "#C5FFFB",
  200: "#8BFFF8",
  300: "#4AFEF4",
  400: "#15ECE7",
  500: "#00D0CE",
  600: "#00A7A8",
  700: primaryDefault,
  800: "#066569",
  900: "#0A5557",
  950: "#003135",
};

export const theme = {
  fontSize: {
    xxs: ".625rem", // 10px
    xs: ".75rem", // 12px
    ssm: ".8125rem", // 13px
    sm: ".875rem", // 14px
    base: "1rem", // 16px
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "4rem",
    "7xl": "4.5rem",
    "8xl": "6rem",
    "9xl": "8rem",
  },
  screens: breakpointsPixels,
  extend: {
    screens: {
      "3xl": "2388px",
    },
    animation: {
      "spin-slow": "spin 3s linear infinite",
    },
    colors: {
      primary: primary,
      green: colors.emerald,
      success: "#67C23A",
      danger: "#E2001A",
      warning: "#F29400",
      info: "#1f82c0",
      gray: slate,
      slate: slate,
    },
  },
  minWidth: {
    0: "0",
    "1/4": "25%",
    "1/2": "50%",
    "3/4": "75%",
    full: "100%",
    44: "11rem",
    48: "12rem",
    52: "13rem",
    96: "24rem",
  },
} satisfies Config["theme"];

export default {
  content: ["src/**/*.vue", "src/**/*.js"],
  theme,
  plugins: [
    containerQueries,
    plugin(function ({ matchUtilities }) {
      matchUtilities({
        "dialog-messagebox": val => {
          return {
            maxWidth: val,
            width: "98vw !important",
            marginTop: "1vh !important",
            marginBottom: "1vh !important",
          };
        },
      });
    }),
    plugin(function ({ matchUtilities }) {
      matchUtilities({
        "animate-duration": val => {
          return {
            "--animate-duration": `${val}ms`,
          }
        }
      });
    }),
  ],
  corePlugins: {
    preflight: true,
  },
  future: {
    hoverOnlyWhenSupported: true,
  },
} satisfies Config;
