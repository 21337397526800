import { Type } from "@artesa/model-compiler";
import { StringEnum } from "../typebox/index.js";
import { uiScope } from "./custom-forms-shared.js";
import {
  uiSelectUserInput,
  uiSelectAssignmentTypeInput,
  uiSelectProjectInput,
  uiSelectAssignmentInput,
  uiSelectAssignmentEventTypeInput,
  uiSelectCustomerInput,
  uiCardCustomer,
  uiCardAddress
} from "./custom-forms-relations.js";
import { printSettings } from "./print-settings.schema.js";
export * from "./custom-forms-shared.js";
export * from "./custom-forms-relations.js";
export * from "./json-schema-type.js";
export const uiTextInput = Type.Object(
  {
    type: Type.Literal("TextInput"),
    comment: Type.Optional(Type.String()),
    scope: uiScope,
    class: Type.Optional(Type.String()),
    containerClass: Type.Optional(Type.String()),
    containerStyle: Type.Optional(Type.String()),
    default: Type.Optional(Type.String()),
    title: Type.Optional(Type.String()),
    description: Type.Optional(Type.String()),
    labelClass: Type.Optional(Type.String()),
    labelStyle: Type.Optional(Type.String()),
    hide: Type.Optional(Type.String()),
    show: Type.Optional(Type.String()),
    options: Type.Optional(
      Type.Object({
        placeholder: Type.Optional(Type.String()),
        resize: Type.Optional(StringEnum(["none", "both", "horizontal", "vertical"]))
      })
    )
  },
  { $id: "TextInput" }
);
export function isUiTextInput(value) {
  return value instanceof Object && "type" in value && value.type === "TextInput";
}
export const uiBooleanInput = Type.Object(
  {
    type: Type.Literal("BooleanInput"),
    comment: Type.Optional(Type.String()),
    scope: uiScope,
    class: Type.Optional(Type.String()),
    containerClass: Type.Optional(Type.String()),
    containerStyle: Type.Optional(Type.String()),
    default: Type.Optional(Type.Union([Type.Boolean(), Type.String()])),
    title: Type.Optional(Type.String()),
    description: Type.Optional(Type.String()),
    labelClass: Type.Optional(Type.String()),
    labelStyle: Type.Optional(Type.String()),
    hide: Type.Optional(Type.String()),
    show: Type.Optional(Type.String()),
    options: Type.Optional(
      Type.Object({
        text: Type.Optional(Type.String()),
        switch: Type.Optional(
          Type.Object({
            activeText: Type.Optional(Type.String()),
            inactiveText: Type.Optional(Type.String())
          })
        ),
        checkbox: Type.Optional(
          Type.Object({
            activeText: Type.Optional(Type.String()),
            inactiveText: Type.Optional(Type.String())
          })
        )
      })
    )
  },
  { $id: "BooleanInput" }
);
export function isUiBooleanInput(value) {
  return value instanceof Object && "type" in value && value.type === "BooleanInput";
}
export const uiRadioInput = Type.Object(
  {
    type: Type.Literal("RadioInput"),
    comment: Type.Optional(Type.String()),
    scope: uiScope,
    class: Type.Optional(Type.String()),
    containerClass: Type.Optional(Type.String()),
    containerStyle: Type.Optional(Type.String()),
    default: Type.Optional(Type.Union([Type.String(), Type.String()])),
    title: Type.Optional(Type.String()),
    description: Type.Optional(Type.String()),
    labelClass: Type.Optional(Type.String()),
    labelStyle: Type.Optional(Type.String()),
    hide: Type.Optional(Type.String()),
    show: Type.Optional(Type.String()),
    options: Type.Object({
      options: Type.Array(
        Type.Object({
          label: Type.String(),
          value: Type.Any()
        })
      ),
      size: Type.Optional(StringEnum(["small", "default", "large"])),
      direction: Type.Optional(StringEnum(["horizontal", "vertical"]))
    })
  },
  { $id: "RadioInput" }
);
export function isUiRadioInput(value) {
  return value instanceof Object && "type" in value && value.type === "RadioInput";
}
export const uiText = Type.Object(
  {
    type: Type.Literal("Text"),
    comment: Type.Optional(Type.String()),
    scope: Type.Optional(Type.String()),
    class: Type.Optional(Type.String()),
    containerClass: Type.Optional(Type.String()),
    containerStyle: Type.Optional(Type.String()),
    title: Type.Optional(Type.String()),
    description: Type.Optional(Type.String()),
    labelClass: Type.Optional(Type.String()),
    labelStyle: Type.Optional(Type.String()),
    hide: Type.Optional(Type.String()),
    show: Type.Optional(Type.String()),
    value: Type.String(),
    tag: Type.Optional(StringEnum(["p", "h1", "h2", "h3", "h4"]))
  },
  { $id: "Text" }
);
export function isUiText(value) {
  return value instanceof Object && "type" in value && value.type === "Text";
}
export const uiHtml = Type.Object(
  {
    type: Type.Literal("HTML"),
    comment: Type.Optional(Type.String()),
    scope: Type.Optional(Type.String()),
    class: Type.Optional(Type.String()),
    containerClass: Type.Optional(Type.String()),
    containerStyle: Type.Optional(Type.String()),
    title: Type.Optional(Type.String()),
    description: Type.Optional(Type.String()),
    labelClass: Type.Optional(Type.String()),
    labelStyle: Type.Optional(Type.String()),
    hide: Type.Optional(Type.String()),
    show: Type.Optional(Type.String()),
    html: Type.String()
  },
  { $id: "HTML" }
);
export function isUiHtml(value) {
  return value instanceof Object && "type" in value && value.type === "HTML";
}
export const uiNumberInput = Type.Object(
  {
    type: Type.Literal("NumberInput"),
    comment: Type.Optional(Type.String()),
    scope: uiScope,
    class: Type.Optional(Type.String()),
    containerClass: Type.Optional(Type.String()),
    containerStyle: Type.Optional(Type.String()),
    default: Type.Optional(Type.Union([Type.String(), Type.Number()])),
    title: Type.Optional(Type.String()),
    description: Type.Optional(Type.String()),
    labelClass: Type.Optional(Type.String()),
    labelStyle: Type.Optional(Type.String()),
    hide: Type.Optional(Type.String()),
    show: Type.Optional(Type.String()),
    options: Type.Optional(
      Type.Object({
        placeholder: Type.Optional(Type.String()),
        min: Type.Optional(Type.Number()),
        max: Type.Optional(Type.Number()),
        step: Type.Optional(Type.Number()),
        stepStrictly: Type.Optional(Type.Boolean()),
        precision: Type.Optional(Type.Number()),
        controls: Type.Optional(Type.Union([Type.Literal("right"), Type.Boolean()]))
      })
    )
  },
  { $id: "NumberInput" }
);
export function isUiNumberInput(value) {
  return value instanceof Object && "type" in value && value.type === "NumberInput";
}
export const uiDateInput = Type.Object(
  {
    type: Type.Literal("DateInput"),
    comment: Type.Optional(Type.String()),
    scope: uiScope,
    class: Type.Optional(Type.String()),
    containerClass: Type.Optional(Type.String()),
    containerStyle: Type.Optional(Type.String()),
    default: Type.Optional(Type.Union([Type.Boolean(), Type.String()])),
    title: Type.Optional(Type.String()),
    description: Type.Optional(Type.String()),
    labelClass: Type.Optional(Type.String()),
    labelStyle: Type.Optional(Type.String()),
    hide: Type.Optional(Type.String()),
    show: Type.Optional(Type.String()),
    options: Type.Optional(
      Type.Object({
        format: Type.Optional(Type.String()),
        formatMinimum: Type.Optional(Type.String()),
        formatMaximum: Type.Optional(Type.String()),
        formatExclusiveMinimum: Type.Optional(Type.String()),
        formatExclusiveMaximum: Type.Optional(Type.String())
      })
    )
  },
  { $id: "DateInput" }
);
export function isUiDateInput(value) {
  return value instanceof Object && "type" in value && value.type === "DateInput";
}
export const uiTimeInput = Type.Object(
  {
    type: Type.Literal("TimeInput"),
    comment: Type.Optional(Type.String()),
    scope: uiScope,
    class: Type.Optional(Type.String()),
    containerClass: Type.Optional(Type.String()),
    containerStyle: Type.Optional(Type.String()),
    default: Type.Optional(Type.Union([Type.Boolean(), Type.String()])),
    title: Type.Optional(Type.String()),
    description: Type.Optional(Type.String()),
    labelClass: Type.Optional(Type.String()),
    labelStyle: Type.Optional(Type.String()),
    hide: Type.Optional(Type.String()),
    show: Type.Optional(Type.String()),
    options: Type.Optional(
      Type.Object({
        valueType: Type.Optional(
          Type.Union([Type.Literal("number"), Type.Literal("date"), Type.Literal("time")])
        )
      })
    )
  },
  { $id: "TimeInput" }
);
export function isUiTimeInput(value) {
  return value instanceof Object && "type" in value && value.type === "TimeInput";
}
export const uiDurationInput = Type.Object(
  {
    type: Type.Literal("DurationInput"),
    comment: Type.Optional(Type.String()),
    scope: uiScope,
    class: Type.Optional(Type.String()),
    containerClass: Type.Optional(Type.String()),
    containerStyle: Type.Optional(Type.String()),
    default: Type.Optional(Type.Union([Type.Boolean(), Type.String()])),
    title: Type.Optional(Type.String()),
    description: Type.Optional(Type.String()),
    labelClass: Type.Optional(Type.String()),
    labelStyle: Type.Optional(Type.String()),
    hide: Type.Optional(Type.String()),
    show: Type.Optional(Type.String()),
    options: Type.Optional(Type.Object({}))
  },
  { $id: "DurationInput" }
);
export function isUiDurationInput(value) {
  return value instanceof Object && "type" in value && value.type === "DurationInput";
}
export const uiDateTimeInput = Type.Object(
  {
    type: Type.Literal("DateTimeInput"),
    comment: Type.Optional(Type.String()),
    scope: uiScope,
    class: Type.Optional(Type.String()),
    containerClass: Type.Optional(Type.String()),
    containerStyle: Type.Optional(Type.String()),
    default: Type.Optional(Type.Union([Type.Boolean(), Type.String()])),
    title: Type.Optional(Type.String()),
    description: Type.Optional(Type.String()),
    labelClass: Type.Optional(Type.String()),
    labelStyle: Type.Optional(Type.String()),
    hide: Type.Optional(Type.String()),
    show: Type.Optional(Type.String()),
    options: Type.Optional(
      Type.Object({
        defaultDate: Type.Optional(Type.String()),
        defaultTime: Type.Optional(Type.String()),
        dateDisabled: Type.Optional(Type.Union([Type.String(), Type.Boolean()])),
        timeDisabled: Type.Optional(Type.Union([Type.String(), Type.Boolean()])),
        showButtonToday: Type.Optional(Type.Boolean())
      })
    )
  },
  { $id: "DateTimeInput" }
);
export function isUiDateTimeInput(value) {
  return value instanceof Object && "type" in value && value.type === "DateTimeInput";
}
export const uiSelectInput = Type.Object(
  {
    type: Type.Literal("SelectInput"),
    comment: Type.Optional(Type.String()),
    scope: uiScope,
    class: Type.Optional(Type.String()),
    containerClass: Type.Optional(Type.String()),
    containerStyle: Type.Optional(Type.String()),
    default: Type.Optional(Type.Union([Type.String(), Type.String()])),
    title: Type.Optional(Type.String()),
    description: Type.Optional(Type.String()),
    labelClass: Type.Optional(Type.String()),
    labelStyle: Type.Optional(Type.String()),
    hide: Type.Optional(Type.String()),
    show: Type.Optional(Type.String()),
    options: Type.Object({
      options: Type.Array(
        Type.Union([
          Type.String(),
          Type.Object({
            label: Type.String(),
            value: Type.Union([Type.String(), Type.Record(Type.String(), Type.Any())])
          })
        ])
      ),
      multi: Type.Optional(Type.Boolean()),
      valueKey: Type.Optional(Type.String()),
      clearable: Type.Optional(Type.Boolean()),
      placeholder: Type.Optional(Type.String())
    })
  },
  { $id: "SelectInput" }
);
export function isUiSelectInput(value) {
  return value instanceof Object && "type" in value && value.type === "SelectInput";
}
export const uiDropdownButtonInput = Type.Object(
  {
    type: Type.Literal("DropdownButtonInput"),
    comment: Type.Optional(Type.String()),
    scope: uiScope,
    class: Type.Optional(Type.String()),
    containerClass: Type.Optional(Type.String()),
    containerStyle: Type.Optional(Type.String()),
    default: Type.Optional(Type.Union([Type.String(), Type.String()])),
    title: Type.Optional(Type.String()),
    description: Type.Optional(Type.String()),
    labelClass: Type.Optional(Type.String()),
    labelStyle: Type.Optional(Type.String()),
    hide: Type.Optional(Type.String()),
    show: Type.Optional(Type.String()),
    options: Type.Object({
      options: Type.Array(
        Type.Union([
          Type.String(),
          Type.Object({
            label: Type.String(),
            value: Type.Union([Type.String(), Type.Record(Type.String(), Type.Any())])
          })
        ])
      ),
      multi: Type.Optional(Type.Boolean()),
      allowItemMultiple: Type.Optional(Type.Boolean()),
      trigger: Type.Optional(StringEnum(["hover", "click", "contextmenu"])),
      hideOnClick: Type.Optional(Type.Boolean()),
      button: Type.Optional(
        Type.Object({
          label: Type.Optional(Type.String()),
          type: Type.Optional(
            StringEnum(["primary", "success", "info", "warning", "danger"])
          ),
          plain: Type.Optional(Type.Boolean()),
          round: Type.Optional(Type.Boolean()),
          circle: Type.Optional(Type.Boolean()),
          text: Type.Optional(Type.Boolean())
        })
      )
    })
  },
  { $id: "DropdownButtonInput" }
);
export function isUiDropdownButtonInput(value) {
  return value instanceof Object && "type" in value && value.type === "DropdownButtonInput";
}
export const uiButtonInput = Type.Object(
  {
    type: Type.Literal("ButtonInput"),
    comment: Type.Optional(Type.String()),
    scope: uiScope,
    class: Type.Optional(Type.String()),
    containerClass: Type.Optional(Type.String()),
    containerStyle: Type.Optional(Type.String()),
    title: Type.Optional(Type.String()),
    description: Type.Optional(Type.String()),
    labelClass: Type.Optional(Type.String()),
    labelStyle: Type.Optional(Type.String()),
    hide: Type.Optional(Type.String()),
    show: Type.Optional(Type.String()),
    options: Type.Object({
      label: Type.Optional(Type.String()),
      type: Type.Optional(StringEnum(["primary", "success", "info", "warning", "danger"])),
      plain: Type.Optional(Type.Boolean()),
      round: Type.Optional(Type.Boolean()),
      circle: Type.Optional(Type.Boolean()),
      text: Type.Optional(Type.Boolean()),
      multi: Type.Optional(Type.Boolean()),
      value: Type.Any()
    })
  },
  { $id: "ButtonInput" }
);
export function isUiButtonInput(value) {
  return value instanceof Object && "type" in value && value.type === "ButtonInput";
}
export const uiSignatureInput = Type.Object(
  {
    type: Type.Literal("SignatureInput"),
    comment: Type.Optional(Type.String()),
    scope: uiScope,
    class: Type.Optional(Type.String()),
    containerClass: Type.Optional(Type.String()),
    containerStyle: Type.Optional(Type.String()),
    default: Type.Optional(Type.Union([Type.String(), Type.String()])),
    title: Type.Optional(Type.String()),
    description: Type.Optional(Type.String()),
    labelClass: Type.Optional(Type.String()),
    labelStyle: Type.Optional(Type.String()),
    hide: Type.Optional(Type.String()),
    show: Type.Optional(Type.String()),
    options: Type.Optional(
      Type.Object({
        defaultName: Type.Optional(
          Type.Object({
            type: StringEnum(["customer", "user"]),
            scope: uiScope,
            default: Type.Optional(Type.String())
          })
        )
      })
    )
  },
  { $id: "SignatureInput" }
);
export const signatureSchema = Type.Object({
  signature: Type.String(),
  timestamp: Type.String({ format: "date-time" }),
  name: Type.String()
});
export function isUiSignatureInput(value) {
  return value instanceof Object && "type" in value && value.type === "SignatureInput";
}
export const uiDrawInput = Type.Object(
  {
    type: Type.Literal("DrawInput"),
    comment: Type.Optional(Type.String()),
    scope: uiScope,
    class: Type.Optional(Type.String()),
    containerClass: Type.Optional(Type.String()),
    containerStyle: Type.Optional(Type.String()),
    title: Type.Optional(Type.String()),
    description: Type.Optional(Type.String()),
    labelClass: Type.Optional(Type.String()),
    labelStyle: Type.Optional(Type.String()),
    hide: Type.Optional(Type.String()),
    show: Type.Optional(Type.String()),
    options: Type.Optional(
      Type.Object({
        fullscreenClass: Type.Optional(Type.String())
      })
    )
  },
  { $id: "DrawInput" }
);
export function isUiDrawInput(value) {
  return value instanceof Object && "type" in value && value.type === "DrawInput";
}
export const uiFileInput = Type.Object(
  {
    type: Type.Literal("FileInput"),
    comment: Type.Optional(Type.String()),
    scope: uiScope,
    class: Type.Optional(Type.String()),
    containerClass: Type.Optional(Type.String()),
    containerStyle: Type.Optional(Type.String()),
    title: Type.Optional(Type.String()),
    description: Type.Optional(Type.String()),
    labelClass: Type.Optional(Type.String()),
    labelStyle: Type.Optional(Type.String()),
    hide: Type.Optional(Type.String()),
    show: Type.Optional(Type.String()),
    options: Type.Optional(
      Type.Object({
        allowedMimeTypes: Type.Optional(Type.Array(Type.String())),
        imageResize: Type.Optional(
          Type.Object({
            width: Type.Optional(Type.Number()),
            height: Type.Optional(Type.Number()),
            quality: Type.Optional(Type.Number())
          })
        )
      })
    )
  },
  { $id: "FileInput" }
);
export function isUiFileInput(value) {
  return value instanceof Object && "type" in value && value.type === "FileInput";
}
export const uiImage = Type.Object(
  {
    type: Type.Literal("Image"),
    comment: Type.Optional(Type.String()),
    class: Type.Optional(Type.String()),
    hide: Type.Optional(Type.String()),
    show: Type.Optional(Type.String()),
    value: Type.String()
  },
  { $id: "Image" }
);
export function isUiImage(value) {
  return value instanceof Object && "type" in value && value.type === "Image";
}
const _uiAnyTableChild = [
  uiTextInput,
  uiBooleanInput,
  uiRadioInput,
  uiNumberInput,
  uiDateInput,
  uiTimeInput,
  uiDurationInput,
  uiDateTimeInput,
  uiSelectInput,
  uiDropdownButtonInput,
  uiButtonInput,
  uiSelectUserInput,
  uiSelectAssignmentTypeInput,
  uiSelectProjectInput,
  uiSelectAssignmentInput,
  uiSelectAssignmentEventTypeInput,
  uiSelectCustomerInput,
  uiCardAddress,
  uiCardCustomer,
  uiSignatureInput,
  uiDrawInput,
  uiFileInput,
  uiText,
  uiImage,
  uiHtml
];
const _uiAnyTableChildRefs = _uiAnyTableChild.map((x) => Type.Ref(x));
const uiAnyTableChild = Type.Union([..._uiAnyTableChild]);
const uiListInput = Type.Object(
  {
    type: Type.Literal("ListInput"),
    comment: Type.Optional(Type.String()),
    scope: uiScope,
    class: Type.Optional(Type.String()),
    containerClass: Type.Optional(Type.String()),
    containerStyle: Type.Optional(Type.String()),
    hide: Type.Optional(Type.String()),
    show: Type.Optional(Type.String()),
    element: Type.Union(_uiAnyTableChildRefs),
    title: Type.Optional(Type.String()),
    description: Type.Optional(Type.String()),
    labelClass: Type.Optional(Type.String()),
    labelStyle: Type.Optional(Type.String()),
    options: Type.Optional(
      Type.Object({
        listType: Type.Optional(StringEnum(["unordered", "ordered"])),
        addable: Type.Optional(Type.Boolean()),
        removable: Type.Optional(Type.Boolean()),
        draggable: Type.Optional(Type.Boolean())
      })
    )
  },
  { $id: "ListInput" }
);
export function isUiListInput(value) {
  return value instanceof Object && "type" in value && value.type === "ListInput";
}
const uiTableInput = Type.Object(
  {
    type: Type.Literal("TableInput"),
    comment: Type.Optional(Type.String()),
    class: Type.Optional(Type.String()),
    containerClass: Type.Optional(Type.String()),
    containerStyle: Type.Optional(Type.String()),
    hide: Type.Optional(Type.String()),
    show: Type.Optional(Type.String()),
    scope: uiScope,
    title: Type.Optional(Type.String()),
    description: Type.Optional(Type.String()),
    labelClass: Type.Optional(Type.String()),
    labelStyle: Type.Optional(Type.String()),
    rows: Type.Array(
      Type.Object({
        element: Type.Union(_uiAnyTableChildRefs),
        width: Type.Optional(Type.String()),
        title: Type.Optional(Type.String()),
        align: Type.Optional(StringEnum(["left", "center", "right"]))
      })
    ),
    options: Type.Optional(
      Type.Object({
        addable: Type.Optional(Type.Boolean()),
        removable: Type.Optional(Type.Boolean()),
        draggable: Type.Optional(Type.Boolean()),
        zeroEntriesText: Type.Optional(Type.Union([Type.String(), Type.Boolean()])),
        border: Type.Optional(Type.Boolean()),
        hideHeader: Type.Optional(Type.Boolean())
      })
    )
  },
  { $id: "TableInput" }
);
export function isUiTableInput(value) {
  return value instanceof Object && "type" in value && value.type === "TableInput";
}
export const uiContainer = Type.Recursive(
  (This) => Type.Object({
    type: Type.Literal("Container"),
    comment: Type.Optional(Type.String()),
    layout: Type.Optional(StringEnum(["none", "row", "column"])),
    class: Type.Optional(Type.String()),
    hide: Type.Optional(Type.String()),
    show: Type.Optional(Type.String()),
    containerClass: Type.Optional(Type.String()),
    containerStyle: Type.Optional(Type.String()),
    style: Type.Optional(Type.String()),
    title: Type.Optional(Type.String()),
    description: Type.Optional(Type.String()),
    labelClass: Type.Optional(Type.String()),
    labelStyle: Type.Optional(Type.String()),
    elements: Type.Array(
      Type.Union([..._uiAnyTableChildRefs, Type.Ref(uiListInput), Type.Ref(uiTableInput), This])
    ),
    options: Type.Optional(
      Type.Object({
        sizeTitle: Type.Optional(StringEnum(["sm", "md", "lg"]))
      })
    )
  }),
  { $id: "Container" }
);
export function isUiContainer(value) {
  return value instanceof Object && "type" in value && value.type === "Container";
}
export const uiElement = Type.Union([
  uiContainer,
  ..._uiAnyTableChildRefs,
  uiListInput,
  uiTableInput
]);
export function isUiElement(value) {
  return value instanceof Object && "type" in value && value.type === "Container";
}
export const uiSchema = Type.Object(
  {
    elements: Type.Array(uiElement, {
      default: [{ type: "Container", class: "grid grid-cols-1 gap-3", elements: [] }]
    }),
    version: Type.Literal(1, { default: 1 }),
    class: Type.Optional(Type.String()),
    printSettings
  },
  { $id: "ArtesaCustomForm" }
);
export function isUiSchema(value) {
  return value instanceof Object && "elements" in value && "version" in value;
}
export function scopeToJsonSchemaPath(path) {
  return path.split(".").map((value) => {
    if (/[a-zA-Z0-9]+\[\d+\]/gm.test(value)) {
      return `properties.` + value.replace(/\[\d+\]/, ".items");
    }
    return `properties.${value}`;
  }).join(".");
}
export const formTemplateSchemas = [
  uiTextInput,
  uiBooleanInput,
  uiRadioInput,
  uiNumberInput,
  uiDateInput,
  uiTimeInput,
  uiDurationInput,
  uiDateTimeInput,
  uiSelectInput,
  uiDropdownButtonInput,
  uiButtonInput,
  uiSelectUserInput,
  uiCardAddress,
  uiCardCustomer,
  uiSelectAssignmentTypeInput,
  uiSelectProjectInput,
  uiSelectAssignmentInput,
  uiSelectAssignmentEventTypeInput,
  uiSelectCustomerInput,
  uiSignatureInput,
  uiDrawInput,
  uiFileInput,
  uiText,
  uiImage,
  uiHtml,
  uiListInput,
  uiTableInput,
  uiContainer,
  uiElement,
  uiSchema
];
export { printSettingMarginToRem } from "./print-settings.schema.js";
