import { Kind, ReadonlyKind, Type } from '@sinclair/typebox';
export * from '@sinclair/typebox';
import * as prettier from 'prettier';
export { Value } from '@sinclair/typebox/value';
export { TypeCompiler } from '@sinclair/typebox/compiler';

// src/compiler/compile-single-schema.ts

// src/compiler/utils/regex-utils.ts
var _escapeRegExp = (text) => {
  return text.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};
function escapePropertyKey(key) {
  if (/^[a-zA-Z_$][a-zA-Z0-9_]*$/.test(key)) {
    return key;
  }
  return `"${key}"`;
}
function testStringNotInQuotes(str, search) {
  return new RegExp(`(?<!["'])${_escapeRegExp(search)}(?!["'])`).test(str);
}
function regexpNotIn(search, notIn) {
  search = _escapeRegExp(search);
  const n1 = _escapeRegExp(notIn[0]);
  const n2 = _escapeRegExp(notIn[1]);
  return new RegExp(
    search + `(?![^${n1}]*${n2}(?:[^${n1}]*${n1}[^${n2}]*${n2})*[^${n1}]*$)`,
    "g"
  );
}

// src/compiler/utils/parse-utils.ts
function parseUnion(types) {
  return types.split(regexpNotIn(" | ", ["{", "}"]));
}
function isSchemaUnion(option) {
  return option[Kind] === "Union";
}
function isSchemaIntersect(option) {
  return option[Kind] === "Intersect";
}
function isSchemaObject(option) {
  return option[Kind] === "Object";
}
function isSchemaReadonly(options) {
  if (ReadonlyKind in options) {
    return options[ReadonlyKind] === "Readonly";
  }
  if (isSchemaIntersect(options)) {
    return options.allOf.every((value) => value[ReadonlyKind] === "Readonly");
  }
  if (isSchemaUnion(options)) {
    return options.anyOf.some((value) => value[ReadonlyKind] === "Readonly");
  }
  return false;
}
function isSchemaVirtual(option) {
  if ("virtual" in option) {
    return option.virtual === true;
  }
  if (isSchemaIntersect(option)) {
    return option.allOf.every((value) => value.virtual === true);
  }
  if (isSchemaUnion(option)) {
    return option.anyOf.some((value) => value.virtual === true);
  }
  return false;
}

// src/compiler/utils/jsdoc-utils.ts
function stringifyJsDoc(comment, options) {
  const result = [];
  function addLine(comment2) {
    result.push(...comment2.split("\n").map((x) => x.trim()));
  }
  function appendSection(comment2) {
    if (!comment2) {
      return;
    }
    const lines = Array.isArray(comment2) ? comment2 : [comment2];
    if (result.length) {
      addLine("");
    }
    for (const line of lines) {
      addLine(line);
    }
  }
  appendSection(comment);
  if (options && "default" in options) {
    appendSection(`@default ${JSON.stringify(options.default)}`);
  }
  if (options?.readonly) {
    appendSection("@readonly");
  }
  if (options?.deprecated) {
    appendSection("@deprecated");
  }
  if (!result.length) {
    return "";
  }
  let generatedCode = spaces(options?.spaces, "/**\n");
  for (const line of result) {
    generatedCode += spaces(options?.spaces, " * ", line, "\n");
  }
  generatedCode += spaces(options?.spaces, " */\n");
  return generatedCode;
}
function jsDocOptionsForSchema(schema, options) {
  const comment = [];
  if (schema.title) {
    comment.push(schema.title);
  }
  if (schema.description) {
    if (comment.length) {
      comment.push("");
    }
    comment.push(schema.description);
  }
  return {
    comment,
    spaces: options?.spaces,
    ..."default" in schema ? { default: schema.default } : {},
    deprecated: schema.deprecated,
    readonly: isSchemaReadonly(schema)
  };
}

// src/compiler/utils/stringify-utils.ts
function quote(value) {
  return `"${value}"`;
}
function spaces(number, ...append) {
  return `${" ".repeat(number ?? 0)}${append?.join("") ?? ""}`;
}
function newLine(isNewLine, append) {
  if (arguments.length === 0) {
    return "\n";
  }
  return `${stringifyIf(isNewLine, "\n")}${""}`;
}
function stringifyUnion(types) {
  return types.join(" | ");
}
function stringifyProperty(key, value, options) {
  let result = "";
  result += newLine(options?.prependNewLine);
  if (options?.jsDocComment) {
    result += stringifyJsDoc(options.jsDocComment.comment, {
      ...options.jsDocComment,
      spaces: options?.spaces
    });
  }
  result += spaces(options?.spaces);
  result += stringifyIf(options?.readonly, "readonly ");
  if (options?.skipEscapePropertyKey) {
    result += key;
  } else {
    result += escapePropertyKey(key);
  }
  result += stringifyIf(options?.optional, "?");
  result += ": ";
  result += `${value}`;
  if (options?.nullable && !isNullable(value)) {
    result += " | null";
  }
  result += ";";
  result += newLine(options?.appendNewLine);
  return result;
}
function stringifyObject(properties, options) {
  let result = newLine(options?.prependNewLine);
  if (options?.jsDocComment) {
    result += stringifyJsDoc(options.jsDocComment.comment, {
      ...options.jsDocComment,
      spaces: options?.spaces
    });
  }
  result += "{";
  for (const line of properties) {
    result += stringifyProperty(line[0], line[1], {
      ...line[2],
      prependNewLine: true,
      appendNewLine: false,
      spaces: (options?.spaces ?? 0) + 2
    });
  }
  result += `
${spaces(options?.spaces, "}", newLine(options?.appendNewLine))}`;
  return result;
}
function stringifyIf(condition, value) {
  return condition ? value : "";
}
function isNullable(value) {
  return value === "null" || value.endsWith(" | null");
}
function simplifyType(value) {
  if (testStringNotInQuotes(value, "Array<") || testStringNotInQuotes(value, "[]")) {
    return "any[]";
  }
  if (testStringNotInQuotes(value, "Record<")) {
    return "Record<string, any>";
  }
  if (value.includes('"') || value.includes("'")) {
    return "string";
  }
  for (const type of ["string", "number", "Date", "boolean", "undefined", "any", "null", "void"]) {
    if (testStringNotInQuotes(value, type)) {
      return type;
    }
  }
  return value;
}

// src/extended-types/frontend-only.ts
var FrontendOnlyKind = Symbol.for("Artesa/FrontendOnly");
function frontendOnly(schema) {
  const modifiedSchema = schema;
  modifiedSchema[FrontendOnlyKind] = true;
  return modifiedSchema;
}
function isSchemaFrontendOnly(option) {
  if (FrontendOnlyKind in option) {
    return !!option[FrontendOnlyKind];
  }
  if (isSchemaIntersect(option)) {
    return option.allOf.every((value) => FrontendOnlyKind in value && !!value[FrontendOnlyKind]);
  }
  if (isSchemaUnion(option)) {
    return option.anyOf.some((value) => FrontendOnlyKind in value && !!value[FrontendOnlyKind]);
  }
  return false;
}

// src/extended-types/not-in-create.ts
var OnlyNotCreateKind = Symbol.for("Artesa/OnlyNotCreate");
function onlyNotCreate(schema) {
  const modifiedSchema = schema;
  modifiedSchema[OnlyNotCreateKind] = true;
  return modifiedSchema;
}
function isSchemaOnlyNotCreate(option) {
  if (OnlyNotCreateKind in option) {
    return !!option[OnlyNotCreateKind];
  }
  if (isSchemaIntersect(option)) {
    return option.allOf.every((value) => OnlyNotCreateKind in value && !!value[OnlyNotCreateKind]);
  }
  if (isSchemaUnion(option)) {
    return option.anyOf.some((value) => OnlyNotCreateKind in value && !!value[OnlyNotCreateKind]);
  }
  return false;
}
function tSchemaToTsType(schema) {
  switch (schema[Kind]) {
    case "Union": {
      const union = schema;
      return `${union.anyOf.map((value) => tSchemaToTsType(value)).join(" | ")}`;
    }
    case "Object":
      return tSchemaTObjectToTsType(schema);
    case "RegExp":
    case "String":
      return "string";
    case "Integer":
    case "Number":
      return "number";
    case "Boolean":
      return "boolean";
    case "BigInt":
      return "bigint";
    case "Date":
      return "Date";
    case "Literal": {
      if (schema.type === "number") {
        return `${schema.const}`;
      }
      return `"${schema.const}"`;
    }
    case "Never":
      return "never";
    case "Null":
      return "null";
    case "Undefined":
      return "undefined";
    case "Uint8Array":
      return "Uint8Array";
    case "Unknown":
      return "unknown";
    case "Void":
      return "void";
    case "Any":
      return "any";
    case "Array": {
      const array = schema;
      return `(${tSchemaToTsType(array.items)})[]`;
    }
    case "Intersect": {
      const intersect = schema;
      return `${intersect.allOf.map((value) => tSchemaToTsType(value)).join(" & ")}`;
    }
    case "TemplateLiteral":
      console.warn(`TemplateLiteral gets converted to string`);
      return "string";
    default:
      console.warn(`Failed to convert type ${schema[Kind]} to typescript type`, schema);
      return "any";
  }
}
function tSchemaTObjectToTsType(schema) {
  if (Object.keys(schema.properties).length === 0) {
    return `Record<string, any>`;
  }
  const properties = [];
  for (const propertyKey in schema.properties) {
    const propertyOptions = schema.properties[propertyKey];
    properties.push([
      propertyKey,
      tSchemaToTsType(propertyOptions),
      {
        optional: !schema.required?.includes(propertyKey),
        readonly: schema[ReadonlyKind] === "Readonly"
      }
    ]);
  }
  return stringifyObject(properties);
}
function modelSchemaPropertyToType(schema, propertyKey) {
  if (!isSchemaUnion(schema)) {
    return tSchemaToTsType(schema.properties[propertyKey]);
  }
  const types = schema.anyOf.map((value) => tSchemaToTsType(value.properties[propertyKey])).join(" | ");
  const uniq = [...new Set(parseUnion(types))].join(" | ");
  return uniq;
}

// src/compiler/compile-single-schema.ts
function compileSingleSchema(schema, profile, options = {}, servicePath) {
  let generatedCode = `{`;
  const spaces2 = options.spaces ?? 2;
  for (const propertyKey in schema.properties) {
    const propertyOptions = schema.properties[propertyKey];
    const isFrontendOnly = isSchemaFrontendOnly(propertyOptions);
    const onlyNotCreate2 = isSchemaOnlyNotCreate(propertyOptions);
    if (isFrontendOnly && profile === "base") {
      continue;
    }
    if (!isFrontendOnly && profile === "frontend") {
      continue;
    }
    if (onlyNotCreate2 && profile === "create") {
      continue;
    }
    generatedCode += stringifyProperty(propertyKey, tSchemaToTsType(propertyOptions), {
      prependNewLine: true,
      optional: !schema.required?.includes(propertyKey),
      readonly: schema[ReadonlyKind] === "Readonly",
      spaces: spaces2,
      jsDocComment: jsDocOptionsForSchema(propertyOptions)
    });
  }
  if (profile === "frontend" || profile === "baseAndFrontend") {
    for (const relationKey in schema.relations) {
      const relationConfig = schema.relations[relationKey];
      if (!options?.modelMap?.[relationConfig.service]) {
        throw new Error(
          `Relation service ${relationConfig.service} not found in modelMap`
        );
      }
      generatedCode += stringifyProperty(
        relationKey,
        `${options.modelMap[relationConfig.service]}${stringifyIf(relationConfig.asArray, "[]")}`,
        {
          prependNewLine: true,
          spaces: spaces2,
          optional: true,
          nullable: relationConfig.nullable
        }
      );
    }
    for (const relationKey in schema.virtual) {
      const relationConfig = schema.virtual[relationKey];
      if (!options?.modelMap?.[relationConfig.service]) {
        throw new Error(
          `Relation service ${relationConfig.service} not found in modelMap`
        );
      }
      generatedCode += stringifyProperty(
        relationKey,
        `${options.modelMap[relationConfig.service]}${stringifyIf(relationConfig.asArray, "[]")}`,
        {
          prependNewLine: true,
          spaces: spaces2,
          optional: true
        }
      );
    }
  }
  generatedCode += stringifyProperty("__service", quote(servicePath), {
    prependNewLine: true,
    spaces: spaces2,
    optional: true
  });
  generatedCode += "\n}";
  return generatedCode;
}

// src/compiler/compileQuery.ts
function queryProperty(propertyKey, type, options) {
  const simplifiedType = simplifyType(type);
  const comparisonOp = ["number", "Date", "any"].includes(simplifiedType) ? ["$lt", "$lte", "$gt", "$gte"] : [];
  const properties = [
    ...[
      "$eq",
      "$ne"
    ].map((key) => {
      return [key, type, { optional: true }];
    }),
    ...comparisonOp.map((key) => {
      return [key, type, { optional: true }];
    }),
    ...[
      "$in",
      "$nin"
    ].map((key) => {
      return [key, `(${type})[]`, { optional: true }];
    })
  ];
  const additionalOperators = options?.operators ? typeof options.operators === "function" ? [options.operators(type, simplifiedType)] : options.operators.map((fn) => fn(type, simplifiedType)) : [];
  for (const operators of additionalOperators) {
    if (!operators) {
      continue;
    }
    for (const { key, type: type2 } of operators) {
      properties.push([key, type2, { optional: true }]);
    }
  }
  const obj = stringifyObject(properties, {
    spaces: (options?.spaces ?? 0) + 2
  });
  return stringifyProperty(
    propertyKey,
    `${type} | ${obj}`,
    {
      prependNewLine: true,
      spaces: (options?.spaces ?? 0) + 2,
      optional: true,
      jsDocComment: options?.jsDocComment
    }
  );
}
function compileQuery(schema, options) {
  const modelNameBase = options.serviceMaps.base[schema.service];
  const _SharedQuery = `_${modelNameBase}SharedQuery`;
  let generatedCode = "";
  const properties = isSchemaUnion(schema) ? schema.anyOf[0].properties : schema.properties;
  (() => {
    generatedCode += `type ${_SharedQuery} = {`;
    for (const propertyKey in properties) {
      const propertyOptions = properties[propertyKey];
      const isFrontendOnly = isSchemaFrontendOnly(propertyOptions);
      if (isFrontendOnly) {
        continue;
      }
      const _jsDocComment = jsDocOptionsForSchema(propertyOptions);
      generatedCode += queryProperty(propertyKey, modelSchemaPropertyToType(schema, propertyKey), {
        ...options,
        jsDocComment: {
          comment: _jsDocComment.comment,
          deprecated: _jsDocComment.deprecated,
          spaces: _jsDocComment.spaces
        }
      });
    }
    const additionalQueryProperties = schema.query?.additionalProperties ?? {};
    for (const additionalKey in additionalQueryProperties) {
      const additionalProperty = additionalQueryProperties[additionalKey];
      if (additionalProperty.profile && additionalProperty.profile !== "shared") {
        continue;
      }
      generatedCode += queryProperty(additionalKey, additionalProperty.type, options);
    }
    generatedCode += `
${spaces(options?.spaces, "}")}

`;
  })();
  const modelNameFrontend = options.serviceMaps.frontend[schema.service];
  (() => {
    const _ServerQuery = `_${modelNameBase}ServerQuery`;
    generatedCode += `type ${_ServerQuery} = ${_SharedQuery} & {`;
    const additionalQueryProperties = schema.query?.additionalProperties ?? {};
    for (const additionalKey in additionalQueryProperties) {
      const additionalProperty = additionalQueryProperties[additionalKey];
      if (additionalProperty.profile !== "server") {
        continue;
      }
      generatedCode += queryProperty(additionalKey, additionalProperty.type, options);
    }
    generatedCode += `
${spaces(options?.spaces, "}")}

`;
    const modelQueryServer = options.serviceMaps.serverQuery[schema.service];
    generatedCode += `export type ${modelQueryServer} = QueryCommon<${_ServerQuery}>

`;
  })();
  (() => {
    const _FrontendQueryType = `_${modelNameFrontend}Query`;
    generatedCode += `type ${_FrontendQueryType} = ${_SharedQuery} & {`;
    for (const propertyKey in properties) {
      const propertyOptions = properties[propertyKey];
      const isFrontendOnly = isSchemaFrontendOnly(propertyOptions);
      if (!isFrontendOnly) {
        continue;
      }
      const _jsDocComment = jsDocOptionsForSchema(propertyOptions);
      generatedCode += queryProperty(propertyKey, modelSchemaPropertyToType(schema, propertyKey), {
        ...options,
        jsDocComment: {
          comment: _jsDocComment.comment,
          deprecated: _jsDocComment.deprecated,
          spaces: _jsDocComment.spaces
        }
      });
    }
    const additionalQueryProperties = schema.query?.additionalProperties ?? {};
    for (const additionalKey in additionalQueryProperties) {
      const additionalProperty = additionalQueryProperties[additionalKey];
      if (additionalProperty.profile !== "frontend") {
        continue;
      }
      generatedCode += queryProperty(additionalKey, additionalProperty.type, options);
    }
    const modelQueryFrontend = options.serviceMaps.frontendQuery[schema.service];
    generatedCode += `
${spaces(options?.spaces, "}")}

`;
    generatedCode += `export type ${modelQueryFrontend} = QueryCommon<${_FrontendQueryType}>

`;
  })();
  return generatedCode;
}

// src/compiler/compile-register.ts
function compileRegister({
  registerName,
  modelName,
  serviceMap
}) {
  const properties = [];
  for (const key in serviceMap) {
    properties.push([key, serviceMap[key], {}]);
  }
  let generatedCode = `// MARK: ${registerName}

`;
  generatedCode += `export interface ${registerName} ${stringifyObject(properties, { appendNewLine: true })}
`;
  generatedCode += `export type ${modelName}<S extends keyof ${registerName} = keyof ${registerName}> = ${registerName}[S]`;
  return generatedCode;
}

// src/compiler/compile.ts
async function compile(schemas, options = {}) {
  let generatedCode = "";
  generatedCode += "type WithOrAnd<T extends Record<string, any>> = T & { $or?: WithOrAnd<T>[], $and?: WithOrAnd<T>[] }\n\n";
  generatedCode += "type QueryCommon<T extends Record<string, any>> = WithOrAnd<T> & ";
  generatedCode += stringifyObject(
    [
      ["$select", "(keyof T)[]", { optional: true }],
      ["$limit", "number", { optional: true }],
      ["$skip", "number", { optional: true }],
      ["$sort", "{ [key in keyof T]?: 1 | -1 | '1' | '-1' | (number & {}) }", { optional: true }]
    ],
    { appendNewLine: true }
  );
  const serviceFrontendMap = {};
  const serviceBaseMap = {};
  const serviceCreateMap = {};
  const serviceServerQueryMap = {};
  const serviceFrontendQueryMap = {};
  for (const name in schemas) {
    const schema = schemas[name];
    serviceFrontendMap[schema.service] = `${name}Frontend`;
    serviceBaseMap[schema.service] = `${name}Base`;
    serviceCreateMap[schema.service] = `${name}Create`;
    serviceServerQueryMap[schema.service] = `${serviceBaseMap[schema.service]}Query`;
    serviceFrontendQueryMap[schema.service] = `${serviceFrontendMap[schema.service]}Query`;
  }
  for (const name in schemas) {
    const schema = schemas[name];
    generatedCode += "// MARK: " + schema.service + "\n\n";
    (() => {
      const modelName = serviceBaseMap[schema.service];
      if (isSchemaUnion(schema)) {
        generatedCode += `export type ${modelName} = ${stringifyUnion(schema.anyOf.map((value) => compileSingleSchema(value, "base", {}, schema.service)))};

`;
      } else {
        generatedCode += `export interface ${modelName} ${compileSingleSchema(schema, "base", {}, schema.service)};

`;
      }
    })();
    (() => {
      const modelName = serviceCreateMap[schema.service];
      if (isSchemaUnion(schema)) {
        generatedCode += `export type ${modelName} = ${stringifyUnion(schema.anyOf.map((value) => compileSingleSchema(value, "create", {}, schema.service)))};

`;
      } else {
        generatedCode += `export interface ${modelName} ${compileSingleSchema(schema, "create", {}, schema.service)};

`;
      }
    })();
    (() => {
      const modelName = serviceFrontendMap[schema.service];
      if (isSchemaUnion(schema)) {
        generatedCode += `export type ${modelName} = ${stringifyUnion(schema.anyOf.map((value) => compileSingleSchema(value, "baseAndFrontend", { modelMap: serviceFrontendMap }, schema.service)))};

`;
      } else {
        generatedCode += `export interface ${modelName} extends ${name}Base ${compileSingleSchema(schema, "frontend", { modelMap: serviceFrontendMap }, schema.service)};

`;
      }
    })();
    (() => {
      generatedCode += compileQuery(schema, {
        operators: options.queryOperators,
        serviceMaps: {
          base: serviceBaseMap,
          frontend: serviceFrontendMap,
          serverQuery: serviceServerQueryMap,
          frontendQuery: serviceFrontendQueryMap
        }
      });
      generatedCode += "\n\n";
    })();
  }
  [
    ["ModelRegister", "Model", serviceBaseMap],
    ["ModelRegisterCreate", "ModelCreate", serviceCreateMap],
    ["ModelRegisterFrontend", "ModelFrontend", serviceFrontendMap],
    ["QueryRegister", "Query", serviceServerQueryMap],
    ["QueryRegisterFrontend", "QueryFrontend", serviceFrontendQueryMap]
  ].forEach(([registerName, modelName, serviceMap]) => {
    generatedCode += compileRegister({
      registerName,
      modelName,
      serviceMap
    }) + "\n\n";
  });
  (() => {
    generatedCode += "export type ParamsRegister = ";
    generatedCode += stringifyObject([
      [
        "[K in keyof ModelRegister]",
        stringifyObject([
          ["query", `QueryRegister[K]`, { optional: true }],
          ["[key: string]", "any", { skipEscapePropertyKey: true }]
        ]),
        {
          skipEscapePropertyKey: true
        }
      ]
    ]) + "\n\n";
    generatedCode += "export type Params<S extends keyof ModelRegister = keyof ModelRegister> = ParamsRegister[S];\n\n";
  })();
  (() => {
    generatedCode += "export type ParamsFrontendRegister = ";
    generatedCode += stringifyObject([
      [
        "[K in keyof ModelRegisterFrontend]",
        stringifyObject([
          ["query", `QueryRegisterFrontend[K]`, {}],
          ["[key: string]", "any", { skipEscapePropertyKey: true }]
        ]),
        {
          skipEscapePropertyKey: true
        }
      ]
    ]) + "\n\n";
    generatedCode += "export type ParamsFrontend<S extends keyof ModelRegisterFrontend = keyof ModelRegisterFrontend> = ParamsFrontendRegister[S];\n\n";
  })();
  return await prettier.format(generatedCode, {
    parser: "typescript",
    tabWidth: options.spaces ?? 2
  });
}
var Nullable = (schema, options) => {
  return Type.Union([schema, Type.Null()], {
    ..."title" in schema ? { title: schema.title } : {},
    ..."description" in schema ? { description: schema.description } : {},
    ..."default" in schema ? { default: schema.default } : {},
    ..."deprecated" in schema ? { deprecated: schema.deprecated } : {},
    ...options
  });
};
function isSchemaNullable(option) {
  return isSchemaUnion(option) && option.anyOf.some((o) => o[Kind] === "Null");
}
function defineRelations(schema, options) {
  const helper = {
    belongsTo(service, additionalOptions) {
      return {
        service,
        keyHere: "",
        keyThere: "id",
        asArray: false,
        nullable: false,
        ...additionalOptions ?? {}
      };
    },
    belongsToTypeUnsafe(service, additionalOptions) {
      return {
        service,
        keyHere: "",
        keyThere: "id",
        asArray: false,
        nullable: false,
        ...additionalOptions ?? {}
      };
    },
    hasMany(service, foreignKey, additionalOptions) {
      return {
        service,
        keyHere: "id",
        keyThere: foreignKey,
        asArray: true,
        nullable: false,
        ...additionalOptions ?? {}
      };
    },
    hasManyTypeUnsafe(service, foreignKey, additionalOptions) {
      return {
        service,
        keyHere: "id",
        keyThere: foreignKey,
        asArray: true,
        nullable: false,
        ...additionalOptions ?? {}
      };
    }
  };
  const virtualHelper = {
    virtual(service, asArray, getPopulateData, excludeForHasChange) {
      return {
        service,
        asArray,
        virtual: true,
        excludeForHasChange,
        getPopulateData
      };
    }
  };
  const relationMap = options.relations(helper);
  const virtualMap = options.virtual(virtualHelper);
  for (const key in relationMap) {
    const relation = relationMap[key];
    relation.nameAs ||= key;
    if (!relation.keyHere) {
      relation.keyHere = `${key}Id`;
    }
  }
  for (const key in virtualMap) {
    const relation = virtualMap[key];
    relation.nameAs ||= key;
  }
  const eachNonArrayRelation = Object.keys(relationMap).map((key) => relationMap[key]).filter((relation) => !relation.asArray);
  const relationIdProperties = {};
  for (const relation of eachNonArrayRelation) {
    const backendIdKey = `${relation.nameAs}Id`;
    const frontendUuidKey = `${relation.nameAs}Uuid`;
    const isNullable2 = relation.nullable;
    relationIdProperties[backendIdKey] = isNullable2 ? Type.Optional(Nullable(Type.Number())) : Type.Number();
    relationIdProperties[frontendUuidKey] = onlyNotCreate(
      frontendOnly(
        isNullable2 ? Type.Optional(Nullable(Type.String({ virtual: true }))) : Type.String({ virtual: true })
      )
    );
  }
  relationIdProperties.id = onlyNotCreate(Type.Number());
  relationIdProperties.uuid = frontendOnly(onlyNotCreate(Type.String()));
  const mergedSchema = Type.Composite([schema, Type.Object(relationIdProperties)]);
  mergedSchema.relations = relationMap;
  mergedSchema.virtual = virtualMap;
  return mergedSchema;
}

// src/extended-types/attach-service.ts
function attachToSchema(schema, options) {
  Object.assign(schema, options);
  return schema;
}
var schemaRegister = /* @__PURE__ */ new Map();
function defineSchema(service, properties, options) {
  const relations = options?.relations ?? (() => ({}));
  const virtual = options?.virtual ?? (() => ({}));
  const attached = {
    service,
    query: options?.query ?? {}
  };
  if (Array.isArray(properties)) {
    const schema = attachToSchema(
      Type.Union(
        properties.map(
          (value) => attachToSchema(
            defineRelations(
              Type.Object(value),
              {
                relations,
                virtual
              }
            ),
            attached
          )
        )
      ),
      attached
    );
    schemaRegister.set(service, schema);
    return schema;
  } else {
    const schema = attachToSchema(
      defineRelations(
        Type.Object(properties),
        {
          relations,
          virtual
        }
      ),
      attached
    );
    schemaRegister.set(service, schema);
    return schema;
  }
}
var StringEnum = (allowedValues, options) => {
  const toRecord = {};
  allowedValues.forEach((value) => toRecord[value] = value);
  return Type.Enum(toRecord, options);
};
var NumberEnum = (allowedValues, options) => {
  return Type.Union(
    allowedValues.map((value) => Type.Literal(value)),
    options
  );
};

export { FrontendOnlyKind, Nullable, NumberEnum, OnlyNotCreateKind, StringEnum, attachToSchema, compile, compileQuery, compileSingleSchema, defineRelations, defineSchema, frontendOnly, isSchemaFrontendOnly, isSchemaIntersect, isSchemaNullable, isSchemaObject, isSchemaOnlyNotCreate, isSchemaReadonly, isSchemaUnion, isSchemaVirtual, onlyNotCreate, schemaRegister };
