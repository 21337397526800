import { Type } from "@artesa/model-compiler";
import { DateSchema, StringEnum } from "../../typebox/index.js";
export const USER_ABSENCE_PARAM_WITH_WORKDAYS = "$withWorkdays";
export const USER_ABSENCE_MULTI = false;
export const USER_ABSENCE_METHODS = [
  "find",
  "get",
  "create",
  "update",
  "patch",
  "remove",
  "calculateAbsenceDays"
];
export const userAbsenceCalculateAbsenceDaysDataSchema = Type.Object(
  {
    companyId: Type.Integer({ minimum: 1 }),
    userIds: Type.Array(Type.Integer({ minimum: 1 }), { minItems: 1 }),
    absenceId: Type.Optional(Type.Integer({ minimum: 1 })),
    typeId: Type.Integer({ minimum: 1 }),
    startsAt: Type.Union([Type.String({ format: "date-time" }), DateSchema()]),
    endsAt: Type.Union([Type.String({ format: "date-time" }), DateSchema()]),
    status: Type.Optional(StringEnum(["approved", "draft"]))
  },
  { additionalProperties: false }
);
