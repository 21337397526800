import { UserAbsence, USER_ABSENCE_PARAM_WITH_WORKDAYS, USER_ABSENCE_MULTI } from "@artesa/shared";
import { paramsForServer } from "feathers-utils";
import { defineFeathersPiniaStore } from "../../base-store";
import { makeTransformDatesHook } from "../../hooks/transformDates";

const servicePath = "user-absences" as const;

export const useUserAbsenceStore = defineFeathersPiniaStore({
  schema: UserAbsence,
  servicePath,
  multi: USER_ABSENCE_MULTI,
  setupHook({ service, transformDates }) {
    const transformDatesHook = makeTransformDatesHook(transformDates);
    service.hooks({
      before: {
        all: [paramsForServer(USER_ABSENCE_PARAM_WITH_WORKDAYS)],
      },
      after: {
        find: [transformDatesHook],
        get: [transformDatesHook],
        create: [transformDatesHook],
        update: [transformDatesHook],
        patch: [transformDatesHook],
        remove: [transformDatesHook],
      },
    });
  },
});

export default useUserAbsenceStore;
